import { Carousel, Slide } from "vue-carousel";
import { VueEditor } from "vue2-editor";
import { mapState } from "vuex";
import { mapGetters } from 'vuex'
import { mapActions } from "vuex";
import addejercevotacion from "@/components/addejercevotacion.vue";
import addejercevotacion2 from "@/components/addejercevotacion2.vue";
import jsPDF from 'jspdf';

export default {
  name: "asambleas",
  components: {

    addejercevotacion,
    addejercevotacion2,
    Carousel,
    Slide,
    VueEditor,

    name: "App"


  },
  data() {
    return {
      search: "",
      pagination: {},

      image: require('@/assets/b1.jpg'),
      image2: require('@/assets/b2.jpg'),
      image3: require('@/assets/b3.jpg'),
      image4: require('@/assets/b4.jpg'),
      image5: require('@/assets/b5.jpg'),
      image6: require('@/assets/b6.jpg'),

      dialog: false,
      headers: [
        { text: "", value: "edit", sortable: false },
        {
          text: "Asamblea",
          align: "left",
          sortable: false,
          value: "asamblea"
        },
        { text: "Fecha", value: "Fecha", sortable: false },
        { text: "Estado", value: "Estado", sortable: false }
      ],
      forpdf: [
        {

          id: "1"

        }
      ],
      asambleaDataList: []
    };
  },





  computed: {
    // convert the list of events into a map of lists keyed by date
    ...mapState("gestion-asamblea-escritorio", ["gestion-asamblea-escritorio"]),
    ...mapGetters({
      authenticated: 'auth/authenticated',
      usuario: 'auth/user',
      getasamblea: 'asambleacurso/asambleasget',
      getasambleaModal: 'asambleacursomodal/asambleasget',
    }),
    ...mapActions({
      add: "gestionaasambleaescritorio/gestionaasambleaescritorio"
    })
  },


  methods: {
    createPDF() {
      var headers2 = [
        {
          id: "id",
          name: "id",
          prompt: "Id",
          width: 50,
          color: "#fff",
          align: "center",
          padding: 5
        }
      ]

      var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "l" });
      doc.text("Reporte de Asistencia", 95, 40);
      doc.table(50, 50, this.forpdf, headers2, { headerBackgroundColor: '#8DAF0B', autoSize: false, format: 'a6', text: "#fff" });
      doc.save('ReporteDeAsistencia.pdf');
    },
  }
  ,
  mounted() {
    this.$store.dispatch("asambleacurso/fetchasambleas").then(
      response => {
        this.asambleaDataList = response;
      }
    );
    this.$store.dispatch("asambleacursomodal/fetchasambleas").then(
      response => {
        this.asambleaDataListModal = response;
      }
    );
    // window.onload = function Ejemplo2() {
    //   setTimeout(function () {
    //     // this.$refs.vac1.startCountdown(true);
    //     this.dialog = true;
    //     alert('Evemnto');
    //   }, 3000);

    // }

  }


}



