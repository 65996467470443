import { mapState } from "vuex";
import jsPDF from 'jspdf';
import addeleccion from "@/components/addeleccion.vue";


export default {
  name: 'Home',
  components: {
    addeleccion
  }, data() {
    return {
      output: null,
      radioGroup: 1,
      search: '',
      pagination: {},
      admin: [],
      presidente: [],
      principal: [],
      voto: [],
      voz: [],
      headers: [
        { text: '', value: 'edit', sortable: false, class:"primary" },
        { text: 'Cve. Encuesta', align: 'left', sortable: false, value: 'encuesta', class:"primary" },
        { text: 'Descripcion', value: '', sortable: false, class:"primary" },
        { text: 'Registrado por', value: '', sortable: false, class:"primary" },
        { text: 'Fecha Registro', value: '', sortable: false, class:"primary" },
        { text: 'Estado', value: 'presidente', sortable: false, class:"primary"},

      ],
      forpdf: [
        {
          encuesta: "0920-19-001",
          register_by: "Orlando Cabrera",
          date_register: "08/12/2012",
          description: "Revision de estados Financieros",
          state: "En curso"
        },
        {
          encuesta: "0920-19-001",
          register_by: "Orlando Cabrera",
          date_register: "08/12/2012",
          description: "Revision de estados Financieros",
          state: "En curso"
        },
        {
          encuesta: "0920-19-001",
          register_by: "Orlando Cabrera",
          date_register: "08/12/2012",
          description: "Revision de estados Financieros",
          state: "En curso"
        },
        {
          encuesta: "0920-19-001",
          register_by: "Orlando Cabrera",
          date_register: "08/12/2012",
          description: "Revision de estados Financieros",
          state: "En curso"
        },
        {
          encuesta: "0920-19-001",
          register_by: "Orlando Cabrera",
          date_register: "08/12/2012",
          description: "Revision de estados Financieros",
          state: "En curso"
        },
      ]
    }
  },
  methods: {
    createPDF() {

      var headers2 = [
        {
          id: "encuesta",
          name: "encuesta",
          prompt: "Cve. Encuesta",
          width: 50,
          color: "#fff",
          align: "center",
          padding: 5
        },
        {
          id: "description",
          name: "description",
          prompt: "Descripción",
          width: 65,
          align: "center",
          padding: 5
        },
        {
          id: "register_by",
          name: "register_by",
          prompt: "Registrado Por",
          width: 65,
          align: "center",
          padding: 5
        },
        {
          id: "date_register",
          name: "date_register",
          prompt: "Fecha Registro",
          width: 65,
          align: "center",
          padding: 5
        },
        {
          id: "state",
          name: "state",
          prompt: "Estado",
          width: 45,
          align: "center",
          padding: 5
        },
      ]

      var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "l" });
      doc.text("Reporte de Elecciones", 125, 40);
      doc.table(25, 50, this.forpdf, headers2, { headerBackgroundColor: '#8DAF0B', autoSize: false, format: 'a6', text: "#fff" });
      doc.save('Elecciones.pdf');
    },
    which(padron, id) {
      var president = "president-" + id;
      var secretary = "secretary-" + id;
      var admin = "admin-" + id;
      var voice = "voice-" + id;
      var vote = "vote-" + id;
      if (padron === 'admin') {
        if (this.$refs[admin].checked == false) {
          this.$refs[president].removeAttribute("disabled");
          this.$refs[secretary].removeAttribute("disabled");
          this.$refs[admin].removeAttribute("disabled");

          if (this.$refs[voice].checked == true) {
            this.$refs[voice].click();
          }

          if (this.$refs[vote].checked == true) {
            this.$refs[vote].click();
          }

          this.$refs[voice].setAttribute("disabled", "true");

          this.$refs[vote].setAttribute("disabled", "true");

        }
        if (this.$refs[admin].checked == true) {
          this.$refs[president].setAttribute("disabled", "true");
          this.$refs[secretary].setAttribute("disabled", "true");
          this.$refs[admin].removeAttribute("disabled");
          this.$refs[voice].removeAttribute("disabled");
          this.$refs[vote].removeAttribute("disabled");

        }

      }

      if (padron === 'president') {
        if (this.$refs[president].checked == false) {
          this.$refs[president].removeAttribute("disabled");
          this.$refs[secretary].removeAttribute("disabled");
          this.$refs[admin].removeAttribute("disabled");
          if (this.$refs[voice].checked == true) {
            this.$refs[voice].click();
          }

          if (this.$refs[vote].checked == true) {
            this.$refs[vote].click();
          }

          this.$refs[voice].setAttribute("disabled", "true");
          this.$refs[vote].setAttribute("disabled", "true");
        }
        if (this.$refs[president].checked == true) {
          this.$refs[admin].setAttribute("disabled", "true");
          this.$refs[secretary].setAttribute("disabled", "true");
          this.$refs[president].removeAttribute("disabled");
          this.$refs[voice].removeAttribute("disabled");
          this.$refs[vote].removeAttribute("disabled");
        }

      }

      if (padron === 'secretary') {
        if (this.$refs[secretary].checked == false) {
          this.$refs[president].removeAttribute("disabled");
          this.$refs[secretary].removeAttribute("disabled");
          this.$refs[admin].removeAttribute("disabled");

          if (this.$refs[vote].checked == true) {
            this.$refs[vote].click();
          }
          this.$refs[vote].setAttribute("disabled", "true");

        }
        if (this.$refs[secretary].checked == true) {
          this.$refs[admin].setAttribute("disabled", "true");
          this.$refs[president].setAttribute("disabled", "true");
          this.$refs[secretary].removeAttribute("disabled");
          this.$refs[vote].removeAttribute("disabled");
        }

      }

    }
  },
  computed: {
    VoiceDisabled() {

    },
    VoteDisabled() {

    },
    pages() {
      if (this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) return 0

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    },

    ...mapState('encuestas', ['desserts'])
  }
}