<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-card-text v-bind="attrs" v-on="on">
        <v-img :src="image2" aspect-ratio="2" class="rounded-card">
          <v-container>
            <span class="white--text font-weight-bold">Solicitud</span>
            <span class="white--text font-weight-bold"> de Moción</span>
          </v-container>
        </v-img>
      </v-card-text>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Crear Mocion </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn color="primary" flat text @click="dialog = false">
            Guardar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader></v-subheader>
        <div class="list-item-mod">
          <v-app id="inspire">
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      disabled
                      label="No. Mocion"
                      outline
                      v-model="newMotion.CveMocion"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <datepicker
                      :inactive="true"
                      labelpiker="Fecha Registro"
                      :date="newMotion.FechaRegistro"
                      @input="newMotion.FechaRegistro = $event"
                    ></datepicker>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select
                      disabled
                      label="Estado"
                      :items="e7"
                      outline
                      v-model="forGetIdState"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container>
                <v-subheader>Descripción</v-subheader>
                <hr />
                <vue-editor maxlength="25" v-model="newMotion.Mocion" />
              </v-container>
              <v-container class="d-flex justify-space-between">
                <v-flex xs12 sm6 md3>
                  <v-text-field
                    disabled
                    label="Registrado Por:"
                    outline
                    :value="user.Nombre1 + ' ' + user.PrimerApellido"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-btn color="primary" @click="saveMotion(user.IdUsuario)">
                    Enviar
                  </v-btn>
                </v-flex>
              </v-container>
            </v-form>
          </v-app>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>

  <script>
import { mapGetters } from "vuex";
import axios from "axios";
import datepicker from "@/components/datepicker.vue";
export default {
  name: "adduser",
  components: {
    datepicker,
  },
  mounted() {
    this.createCveMocion();
  },
  data() {
    return {
      image: require("@/assets/b1.jpg"),
      image2: require("@/assets/b2.jpg"),
      image3: require("@/assets/b3.jpg"),
      image4: require("@/assets/b4.jpg"),
      image5: require("@/assets/b5.jpg"),
      image6: require("@/assets/b6.jpg"),
      dialog: false,
      forGetIdState: "EN CURSO",
      notifications: false,
      sound: true,
      widgets: false,
      consecutivo: "",
      e7: ["EN CURSO"],
      newMotion: {
        CveMocion: "",
        FechaRegistro: "",
        IdEstado: "7",
        Mocion: "",
        IdUsuario: "",
        IdAsamblea: "5186",
        Quorum: 0,
      },
    };
  },
  methods: {
    async createCveMocion() {
      let responseConsecutivo = await axios.get(
        process.env.VUE_APP_BASE_URL + `/pgeneral/2`
      );
      responseConsecutivo = responseConsecutivo.data.payload;

      this.consecutivo = parseInt(responseConsecutivo[0].Valor) + 1;
      this.consecutivo = this.consecutivo.toString();
      var consecutivo;
      if (this.consecutivo.length == 1) {
        consecutivo = "000" + this.consecutivo;
      }
      if (this.consecutivo.length == 2) {
        consecutivo = "00" + this.consecutivo;
      }
      if (this.consecutivo.length == 3) {
        consecutivo = "0" + this.consecutivo;
      }
      if (this.consecutivo.length == 4) {
        consecutivo = this.consecutivo;
      }
      var dt = new Date();
      var actualMonth = dt.getMonth() + 1;
      this.newMotion.CveMocion =
        "MO-" + dt.getFullYear() + "-" + actualMonth + "-" + consecutivo + 7;
    },

    async saveMotion(iduser) {
      this.newMotion.IdUsuario = iduser;
      await axios
        .post(process.env.VUE_APP_BASE_URL + `/mocion`, this.newMotion)
        .then(() => {
          alert("Mocion Agregada con exito");
          this.createCveMocion();
          this.newMotion.Mocion = "";
          this.dialog = false;
          this.$store.dispatch("mociones/fetchmociones");
        })
        .catch((err) => {
          alert("Ups! Algo anda mal" + "\n" + err);
        });
    },
  },

  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
};
</script>