<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-tile v-bind="attrs" v-on="on"> 
        <v-list-tile-title>Editar</v-list-tile-title>
         <v-list-tile-action>
          <v-icon color="blue darken-2">fa fa-pencil-square-o</v-icon>
        </v-list-tile-action>
      </v-list-tile>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Editar Asamblea</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-items>
          <v-btn color="white" flat text @click="actualizarAsamblea">
            Actualizar Asamblea
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader></v-subheader>
        <div class="list-item-mod">
          <v-app id="inspire">
            <!-- Formulario de actualización de asamblea -->
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      label="Asamblea"
                      outline
                      readonly
                      v-model="edicionAsamblea.CveAsamblea"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <datepicker
                      labelpiker="Fecha Asamblea"
                      :date="edicionAsamblea.FechaAsamblea"
                      @input="edicionAsamblea.FechaAsamblea = $event"
                    ></datepicker>
                  </v-flex>
                  <v-flex xs12 sm6 md4>
                    <v-select
                      v-model="edicionAsamblea.IdEstado"
                      class="elevation-0"
                      :items="catalogoEstados"
                      item-text="Estado"
                      item-value="IdEstado"
                      label="Estado"
                      disabled
                      outline
                    ></v-select>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6 md3 class="mr-3">
                    <timepicker
                      labelpiker="Hora de Inicio"
                      :date="edicionAsamblea.HoraInicio"
                      @input="edicionAsamblea.HoraInicio = $event"
                    ></timepicker>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model="edicionAsamblea.HoraFin"
                      label="Hora Finalizada"
                      outline
                      disabled
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout row wrap>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model.trim="$v.edicionAsamblea.PrimeraLlamada.$model"
                      label="Tiempo de la primera llamada"
                      outline
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      v-model.trim="$v.edicionAsamblea.SegundaLlamada.$model"
                      label="Tiempo de la segunda llamada"
                      outline
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>

              <!-- JUNTA DIRECTIVA -->
              <v-container>
                <v-subheader>Junta Directiva</v-subheader>
                <hr />
                <div class="table-container">
                  <v-data-table
                    :headers="usuariosAdministrativosHeaders"
                    :items="usuariosJuntaDirectiva"
                    :items-per-page="10"
                    :search="searchJuntaDirectiva"
                    :style="headerTableStyles"
                    class="elevation-1"
                  >
                    <template v-slot:items="props">
                      <td style="padding: 0 2px" class="text-xs-center"></td>
                      <td>
                        {{ props.item.nombreCompleto }}
                      </td>
                      <td class="text-xs-right">
                        {{ props.item.Ct_TpPadron.TpPadron }}
                      </td>
                      <td class="text-xs-right">
                        <p>
                          <input
                            type="checkbox"
                            v-model="props.item.asistencia"
                            v-bind:value="props.item.asistencia"
                          />
                        </p>
                      </td>
                    </template>
                  </v-data-table>
                </div>
              </v-container>
              <!-- /JUNTA DIRECTIVA -->

              <!-- TABLAS -->
              <v-container>
                <div class="d-flex">
                  <!-- INTEGRANTES DE ADMINISTRACIÓN -->
                  <div class="mr-5">
                    <v-subheader>Integrantes de Administración</v-subheader>
                    <!-- Dialogo nuevo integrante -->
                    <v-dialog
                      v-model="dialogNuevoIntegranteAdministracion"
                      max-width="600px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on">
                          Agregar integrante de administración
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline"
                            >Agregar integrante de administración</span
                          >
                        </v-card-title>

                        <v-card-text>
                          <v-container grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 sm6>
                                <v-text-field
                                  v-model="nuevoIntegranteAdministracion.Nombre"
                                  label="Nombre"
                                  outline
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                <v-select
                                  v-model="
                                    nuevoIntegranteAdministracion.IdProfesion
                                  "
                                  class="elevation-0"
                                  :items="catalogoProfesiones"
                                  item-text="Profesion"
                                  item-value="IdProfesion"
                                  label="Puesto"
                                  outline
                                ></v-select>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="error"
                            @click="closeNuevoIntegranteAdministracion"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            color="primary"
                            @click="saveNuevoIntegranteAdministracion"
                            >Agregar nuevo integrante</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- /Dialogo nuevo integrante -->
                    <!-- Tabla integrantes -->
                    <div class="table-container">
                      <v-data-table
                        :headers="integranteAdministracionHeaders"
                        :items="usuariosIntegrantesAdministracion"
                        :search="search"
                        :style="headerTableStyles"
                        hide-actions
                        class="elevation-1"
                      >
                        <template v-slot:items="props">
                          <td
                            style="padding: 0 2px"
                            class="text-xs-center"
                          ></td>
                          <td>{{ props.item.Nombre }}</td>
                          <td class="text-xs-right">
                            <v-select
                              v-model="props.item.IdProfesion"
                              class="elevation-0"
                              :items="catalogoProfesiones"
                              item-text="Profesion"
                              item-value="IdProfesion"
                              readonly
                            ></v-select>
                          </td>
                          <td class="text-center">
                            <v-icon
                              small
                              @click="deleteItemInvitado(props.item)"
                            >
                              fa fa-trash
                            </v-icon>
                          </td>
                        </template>
                      </v-data-table>
                    </div>
                    <!-- /Tabla integrantes -->
                  </div>

                  <!-- INVITADOS -->
                  <div class="invitados-container">
                    <v-subheader>Invitados</v-subheader>
                    <v-spacer></v-spacer>
                    <!-- Diálogo nuevo invitado -->
                    <v-dialog v-model="dialogNuevoInvitado" max-width="500px">
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on"
                          >Agregar invitado</v-btn
                        >
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Agregar invitado</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 sm6>
                                <v-text-field
                                  v-model="nuevoInvitado.Nombre"
                                  label="Nombre"
                                  outline
                                ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                <v-select
                                  v-model="nuevoInvitado.IdProfesion"
                                  class="elevation-0"
                                  :items="catalogoProfesiones"
                                  item-text="Profesion"
                                  item-value="IdProfesion"
                                  label="Profesión"
                                  outline
                                ></v-select>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" @click="closeNuevoInvitado"
                            >Cancelar</v-btn
                          >
                          <v-btn color="primary" @click="saveNuevoInvitado"
                            >Agregar nuevo invitado</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <!-- /Diálogo nuevo invitado -->
                    <!-- Tabla invitados -->
                    <div class="table-container">
                      <v-data-table
                        :headers="invitadosHeaders"
                        :items="invitados"
                        :search="search"
                        :style="headerTableStyles"
                        hide-actions
                        class="elevation-1"
                      >
                        <template v-slot:items="props">
                          <td
                            style="padding: 0 2px"
                            class="text-xs-center"
                          ></td>
                          <td>{{ props.item.Nombre }}</td>
                          <td class="text-xs-right">
                            <v-select
                              v-model="props.item.IdProfesion"
                              class="elevation-0"
                              :items="catalogoProfesiones"
                              item-text="Profesion"
                              item-value="IdProfesion"
                              readonly
                            ></v-select>
                          </td>
                          <td class="text-center">
                            <v-icon
                              small
                              @click="deleteItemInvitado(props.item)"
                            >
                              fa fa-trash
                            </v-icon>
                          </td>
                        </template>
                      </v-data-table>
                    </div>
                    <!-- /Tabla invitados -->
                  </div>
                </div>
              </v-container>
              <!-- ÓRDENES DEL DÍA -->
              <v-container>
                <v-subheader>Orden del Día</v-subheader>
                <hr />
                <div class="table-container">
                  <table
                    class="table table-striped v-datatable v-table theme--light"
                    :style="headerTableStyles"
                  >
                    <thead class="thead-dark">
                      <tr>
                        <th scope="col">Orden</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Tiempo/Descisión</th>
                        <th scope="col">Estado</th>
                        <th scope="col">Acciones</th>
                      </tr>
                    </thead>
                    <draggable v-model="listaOrdenDelDia" tag="tbody">
                      <tr
                        class="cursor-move"
                        v-for="orden in listaOrdenDelDia"
                        :key="orden.IdOrden"
                      >
                        <td scope="row">
                          {{ orden.OrdenAsamblea }}
                        </td>
                        <td>{{ orden.Tipo }}</td>
                        <td v-if="orden.Mocion">
                          <span v-html="orden.Mocion"></span>
                        </td>
                        <td v-else-if="orden.Descripcion">
                          <span v-html="orden.Descripcion"></span>
                        </td>
                        <td>
                          <v-text-field
                            v-model="orden.TiempoVotacion"
                          ></v-text-field>
                        </td>
                        <td>{{ orden.Ct_Estado.Estado }}</td>
                        <v-icon small @click="deleteItemOrden(orden)">
                          fa fa-trash
                        </v-icon>
                      </tr>
                    </draggable>
                  </table>
                </div>
              </v-container>
              <!-- MODAL MOCIONES POR ASIGNAR -->
              <v-container clas="mt-5">
                <div class="d-flex">
                  <v-btn
                    color="blue blue-accent-1 white--text"
                    @click="dialogMociones = true"
                    >Agregar Moción</v-btn
                  >
                  <!-- Btn agregar orden manual -->
                  <div class="d-flex">
                    <v-dialog v-model="dialogNuevaOrden" max-width="1000px">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          class="ml-2"
                          color="blue blue-accent-1 white--text"
                          v-on="on"
                          >Agregar orden manual</v-btn
                        >
                      </template>
                      <v-card>
                        <v-card-title>
                          <span class="headline">Agregar orden manual</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container grid-list-md>
                            <v-layout wrap>
                              <v-flex xs12 sm4>
                                <datepicker
                                  labelpiker="Fecha Registro"
                                  :date="nuevaOrden.FechaRegistro"
                                  @input="nuevaOrden.FechaRegistro = $event"
                                ></datepicker>
                              </v-flex>
                              <v-flex xs12 sm4>
                                <v-select
                                  v-model="nuevaOrden.IdEstado"
                                  class="elevation-0"
                                  :items="catalogoEstados"
                                  item-text="Estado"
                                  item-value="IdEstado"
                                  label="Estado"
                                  outline
                                ></v-select>
                              </v-flex>
                              <v-flex xs12>
                                <v-subheader>Descripción de Orden</v-subheader>
                                <hr />
                                <vue-editor v-model="nuevaOrden.Descripcion" />
                              </v-flex>
                              <v-flex xs12 sm4>
                                <v-text-field
                                  disabled
                                  label="Registrado Por:"
                                  outline
                                  :value="usuario.Nombre1"
                                ></v-text-field>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" @click="closeNuevaOrden"
                            >Cancelar</v-btn
                          >
                          <v-btn color="primary" @click="saveNuevaOrden"
                            >Agregar orden</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </div>
                <v-dialog v-model="dialogMociones" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <h2>Mociones por asignar</h2>
                    </v-card-title>
                    <v-card-text>
                      <div class="table-container">
                        <v-data-table
                          v-model="mocionesPorAsignarSeleccionadas"
                          :headers="mocionesPorAsignarHeader"
                          :items="mocionesPorAsignar"
                          :style="headerTableStyles"
                          item-key="IdMocion"
                          select-all
                          hide-actions
                          class="elevation-1"
                        >
                          <template v-slot:headers="props">
                            <tr>
                              <th>
                                <v-checkbox
                                  :input-value="props.all"
                                  :indeterminate="props.indeterminate"
                                  primary
                                  hide-details
                                  @click.stop="
                                    seleccionarTodasLasMocionesPorAsignar
                                  "
                                ></v-checkbox>
                              </th>
                              <th
                                v-for="header in props.headers"
                                :key="header.text"
                              >
                                {{ header.text }}
                              </th>
                            </tr>
                          </template>
                          <template v-slot:items="props">
                            <tr
                              :active="props.selected"
                              @click="props.selected = !props.selected"
                            >
                              <td>
                                <v-checkbox
                                  :input-value="props.selected"
                                  primary
                                  hide-details
                                ></v-checkbox>
                              </td>
                              <td>
                                <span v-html="props.item.Mocion"></span>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="primary"
                        text
                        @click="dialogMociones = false"
                      >
                        Cerrar
                      </v-btn>
                      <v-btn color="primary" text @click="agregarMociones()">
                        Agregar mociones
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-container>
            </v-form>
          </v-app>
        </div>
        <v-container>
          <div class="d-flex">
            <v-btn class="mr-2" color="primary" @click="actualizarAsamblea">
              Actualizar Asamblea
            </v-btn>
            <v-btn class="ml-2" color="error" @click="dialog = false"
              >Cerrar</v-btn
            >
          </div>
        </v-container>
      </v-list>
    </v-card>
    <v-snackbar v-model="snackbar" color="error" :timeout="6000" top>
      {{ errMsg }}
      <v-btn dark flat @click="snackbar = false"> Cerrar </v-btn>
    </v-snackbar>
    <!-- Footer -->
    <!-- /Footer -->
  </v-dialog>
</template>

<script>
import datepicker from "@/components/datepicker.vue";
import timepicker from "@/components/common/timepicker.vue";
import axios from "axios";
import draggable from "vuedraggable";
import { required, between } from "vuelidate/lib/validators";

// Prototipo unique
Array.prototype.unique = function () {
  var a = this.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
};
export default {
  name: "editAsambleas",
  components: {
    datepicker,
    draggable,
    timepicker,
  },
  props: { IdAsamblea: { type: Number, default: 0 } },
  computed: {
    usuario() {
      return this.$store.getters["auth/user"];
    },
    usuariosJuntaDirectivaFinales() {
      return this.usuariosJuntaDirectiva.filter((item) => item.asistencia);
    },
    headerTableStyles() {
      let color = localStorage.getItem("tema");
      return {
        "--bg-color": color,
      };
    },
  },
  data() {
    return {
      searchJuntaDirectiva: "",
      search: "",
      // Headers
      invitadosHeaders: [
        { text: "", value: "edit", sortable: false },
        {
          text: "Invitado",
          align: "left",
          sortable: false,
          value: "Nombre",
        },
        { text: "Profesión", value: "IdPuesto", sortable: false },
        { text: "Acciones", value: "", sortable: false },
      ],
      usuariosAdministrativosHeaders: [
        { text: "", value: "edit", sortable: false },
        {
          text: "Integrante",
          align: "left",
          sortable: false,
          value: "integrante",
        },
        { text: "Puesto", value: "puesto", sortable: false },
        { text: "Asistirá", value: "asistencia", sortable: false },
      ],
      integranteAdministracionHeaders: [
        { text: "", sortable: false },
        {
          text: "Nombre",
          align: "left",
          sortable: false,
          value: "Nombre",
        },
        { text: "Puesto", value: "IdPadron", sortable: false },
        { text: "Acciones", sortable: false },
      ],
      listaOrdenDelDia: [],
      ordenDelDiaDragging: false,
      mocionesPorAsignarHeader: [
        { text: "Mocion", value: "Mocion", sortable: false },
      ],
      mocionesPorAsignar: [],
      invitados: [],
      usuariosIntegrantesAdministracion: [],
      nuevoInvitado: {
        Nombre: "",
        IdProfesion: "",
        Tipo: "IN",
      },
      nuevoInvitadoIndex: -1,
      nuevoInvitadoBase: {
        Nombre: "",
        IdProfesion: "",
        Tipo: "IN",
      },
      nuevoIntegranteAdministracionBase: {
        Nombre: "",
        IdProfesion: "",
        Tipo: "IA",
      },
      nuevoIntegranteAdministracion: {
        Nombre: "",
        IdProfesion: "",
        Tipo: "IA",
      },
      nuevoIntegranteAdministracionIndex: -1,
      dialog: false,
      dialogNuevoInvitado: false,
      dialogMociones: false,
      dialogNuevaOrden: false,
      dialogNuevoIntegranteAdministracion: false,
      catalogoEstados: [],
      catalogoProfesiones: [],
      catalogoPadron: [],
      listaUsuarios: [],
      usuariosJuntaDirectiva: [],
      mocionesPorAsignarSeleccionadas: [],
      edicionAsamblea: {
        CveAsamblea: null,
        FechaAsamblea: null,
        IdEstado: null,
        HoraInicio: null,
        HoraFin: null,
        PrimeraLlamada: null,
        SegundaLlamada: null,
      },
      nuevaAsambleaBase: {
        CveAsamblea: null,
        FechaAsamblea: null,
        IdEstado: null,
        HoraInicio: null,
        HoraFin: null,
        PrimeraLlamada: null,
        SegundaLlamada: null,
      },
      nuevaOrden: {
        IdUsuario: null,
        FechaRegistro: null,
        IdEstado: null,
        Descripcion: null,
        OrdenAsamblea: null,
        TiempoVotacion: null,
      },
      snackbar: false,
      errMsg: "",
      tipoOrden: {
        ORDEN_MANUAL: "ORDEN MANUAL",
        MOCION: "MOCION",
      },
      participantesActivos: [],
    };
  },
  validations: {
    edicionAsamblea: {
      FechaAsamblea: {
        required,
      },
      HoraInicio: {
        required,
      },
      PrimeraLlamada: {
        required,
        between: between(1, 99),
      },
      SegundaLlamada: {
        required,
        between: between(1, 99),
      },
    },
  },
  watch: {
    listaOrdenDelDia(val) {
      for (let i = 0; i < val.length; i++) {
        val[i].OrdenAsamblea = i + 1;
      }
      this.OrdenAsamblea = val;
    },
    dialog(val) {
      if (val) {
        this.loadInitialData();
      }
    },
  },
  methods: {
    async loadInitialData() {
      // Limpia objetos y arrays
      this.usuariosIntegrantesAdministracion = [];
      this.invitados = [];
      this.listaOrdenDelDia = [];
      this.edicionAsamblea = Object.assign({}, this.nuevaAsambleaBase);
      this.edicionAsamblea.IdUsuario = this.usuario.IdUsuario;
      this.mocionesPorAsignarSeleccionadas = [];
      this.edicionAsamblea.IdUsuario = this.usuario.IdUsuario;

      // Obtiene el objeto de la asamblea
      let resAsamblea = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/asamblea/${this.IdAsamblea}`
      );
      // Obtiene usuarios para la tabla "Junta directiva"
      this.participantesActivos = resAsamblea.data.payload.participantesActivos;
      // Obtiene los miebros para la tabla "Integrantes de Administración"
      this.usuariosIntegrantesAdministracion = resAsamblea.data.payload.invitados.filter(
        (item) => item.Tipo === "IA"
      );
      // Obtiene los miebros para la tabla "Invitados"
      this.invitados = resAsamblea.data.payload.invitados.filter(
        (item) => item.Tipo === "IN"
      );

      // Obtiene las mociones de la tabla "Orden del día."
      let listaMociones = resAsamblea.data.payload.asamblea.Mo_Mociones;
      listaMociones.map((item) => {
        item.Tipo = this.tipoOrden.MOCION;
      });
      this.listaOrdenDelDia = listaMociones;

      // Obtiene las Ordenes manuales de la tabla "Orden del día."
      let listaOrdenes = resAsamblea.data.payload.asamblea.As_Ordenes;
      listaOrdenes.map((item) => {
        item.Tipo = this.tipoOrden.ORDEN_MANUAL;
      });
      this.listaOrdenDelDia = this.listaOrdenDelDia.concat(listaOrdenes);

      resAsamblea = resAsamblea.data.payload.asamblea;

      // Formateo de horas
      let horaInicioFormated = resAsamblea.HoraInicio;
      let horaFinFormated = resAsamblea.HoraFin;
      if (horaInicioFormated !== null) {
        horaInicioFormated = horaInicioFormated.split("T")[1];
        horaInicioFormated = horaInicioFormated.replace("Z", "");
        horaInicioFormated = horaInicioFormated.substring(0, 5);
      }

      if (horaFinFormated !== null) {
        horaFinFormated = horaFinFormated.split("T")[1];
        horaFinFormated = horaFinFormated.replace("Z", "");
        horaFinFormated = horaFinFormated.substring(0, 5);
      }
      resAsamblea.HoraInicio = horaInicioFormated;
      resAsamblea.HoraFin = horaFinFormated;

      // Asignacion de objeto de asamblea para editar
      this.edicionAsamblea = resAsamblea;

      // Obtiene catálogo de estados
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/estado/?Catalo=ASAMBLEAS`)
        .then((response) => {
          this.catalogoEstados = response.data.payload;
          // Elige por default la opción "PREVIO"
          let found = this.catalogoEstados.find(
            (item) => item.Estado === "PREVIO"
          );
          this.edicionAsamblea.IdEstado = found.IdEstado;
        });

      // Obtiene el catálogo de profesionesInformacion
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/profesion`)
        .then((response) => {
          this.catalogoProfesiones = response.data.payload;
        });

      // Obtiene el catálogo de Padrón
      axios.get(`${process.env.VUE_APP_BASE_URL}/padron`).then((response) => {
        this.catalogoPadron = response.data.payload;
      });

      // Obtiene la lista de usuarios
      axios.get(`${process.env.VUE_APP_BASE_URL}/usuarios`).then((response) => {
        this.listaUsuarios = response.data.payload;
        // Formatea los objetos
        this.listaUsuarios.map((item) => {
          item.nombreCompleto = "";
          item.nombreCompleto += item.Nombre1;
          if (item.Nombre2) {
            item.nombreCompleto += ` ${item.Nombre2}`;
          }
          item.nombreCompleto += ` ${item.PrimerApellido}`;
          if (item.SegundoApellido) {
            item.nombreCompleto += ` ${item.SegundoApellido}`;
          }
        });

        // Calcula los usuarios de Junta directiva
        this.usuariosJuntaDirectiva = this.listaUsuarios
          .filter((item) => item.Ct_TpPadron)
          .filter((item) => item.Ct_TpPadron.Junta === "S");

        // Activa el checkbox de "Asistirá" en las tablas
        this.usuariosJuntaDirectiva.map((item) => {
          if (
            this.participantesActivos.find(
              (participante) => participante.IdUsuario === item.IdUsuario
            )
          ) {
            item.asistencia = true;
          }
        });
      });

      // Obtiene la lista de mociones "POR ASIGNAR / INACTIVAS"
      axios
        .get(`${process.env.VUE_APP_BASE_URL}/mocion?Estado=POR ASIGNAR`)
        .then((response) => {
          this.mocionesPorAsignar = response.data.payload;
          // Se asigna el campo TIPO, para diferenciar de encuentas y elecciones
          this.mocionesPorAsignar.map((item) => {
            item.Tipo = this.tipoOrden.MOCION;
          });
        });
    },
    closeNuevoInvitado() {
      this.dialogNuevoInvitado = false;
      setTimeout(() => {
        this.nuevoInvitado = Object.assign({}, this.nuevoInvitadoBase);
        this.nuevoInvitadoIndex = -1;
      }, 300);
    },
    /** Cierra el modal de agregado de integrante de administración */
    closeNuevoIntegranteAdministracion() {
      this.dialogNuevoIntegranteAdministracion = false;
      setTimeout(() => {
        this.nuevoIntegranteAdministracion = Object.assign(
          {},
          this.nuevoIntegranteAdministracionBase
        );
        this.nuevoIntegranteAdministracionIndex = -1;
      }, 300);
    },
    /**
     * Agrega el nuevo registro a la tabla de invitados
     */
    saveNuevoInvitado() {
      if (this.nuevoInvitado > -1) {
        Object.assign(
          this.invitados[this.nuevoInvitadoIndex],
          this.nuevoInvitado
        );
      } else if (
        this.nuevoInvitado.Nombre === "" ||
        this.nuevoInvitado.IdProfesion === ""
      ) {
        this.closeNuevoInvitado();
      } else {
        this.invitados.push(this.nuevoInvitado);
      }
      this.closeNuevoInvitado();
    },
    /**
     * Agrega un nuevo registro a la tabla de Integrantes de administración.
     */
    saveNuevoIntegranteAdministracion() {
      if (this.nuevoIntegranteAdministracion > -1) {
        Object.assign(
          this.usuariosIntegrantesAdministracion[
            this.nuevoIntegranteAdministracionIndex
          ],
          this.nuevoIntegranteAdministracion
        );
      } else if (
        this.nuevoIntegranteAdministracion.Nombre === "" ||
        this.nuevoIntegranteAdministracion.IdProfesion === ""
      ) {
        this.closeNuevoIntegranteAdministracion();
      } else {
        this.usuariosIntegrantesAdministracion.push(
          this.nuevoIntegranteAdministracion
        );
      }
      this.closeNuevoIntegranteAdministracion();
    },
    closeNuevaOrden() {
      this.dialogNuevaOrden = false;
    },
    /**
     * Almacena la información de la orden manual en la BDD.
     */
    async saveNuevaOrden() {
      this.nuevaOrden.IdUsuario = this.usuario.IdUsuario;
      this.$store.commit("CHANGE_IS_LOADING", true);

      // Valida que todos los campos necesario no estén vacíos
      if (this.nuevaOrden.FechaRegistro === null) {
        this.errMsg = "No deje el campo 'FechaRegistro' vacío";
        this.snackbar = true;
        this.$store.commit("CHANGE_IS_LOADING", false);

        return;
      }
      if (this.nuevaOrden.IdEstado === null) {
        this.errMsg = "No deje el campo 'IdEstado' vacío";
        this.snackbar = true;
        this.$store.commit("CHANGE_IS_LOADING", false);

        return;
      }
      if (this.nuevaOrden.Descripcion === null) {
        this.errMsg = "No deje el campo 'Descripcion' vacío";
        this.snackbar = true;
        this.$store.commit("CHANGE_IS_LOADING", false);

        return;
      }
      let response = await axios.post(
        `${process.env.VUE_APP_BASE_URL}/orden`,
        this.nuevaOrden
      );
      let idNuevaOrdenCreada = response.data.payload.IdOrden;

      // Obtiene el objeto creado con todas las relaciones de la tabla
      let nuevaOrdenCreada = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/orden/${idNuevaOrdenCreada}`
      );
      nuevaOrdenCreada = nuevaOrdenCreada.data.payload;
      nuevaOrdenCreada.Tipo = this.tipoOrden.ORDEN_MANUAL;
      // Reinicia modelo NuevaOrden
      this.nuevaOrden = {
        IdUsuario: null,
        FechaRegistro: null,
        IdEstado: null,
        Descripcion: null,
        OrdenAsamblea: null,
        TiempoVotacion: null,
      };
      this.agregarOrdenManualAOrdenesdelDia(nuevaOrdenCreada);
      this.closeNuevaOrden();
      this.$store.commit("CHANGE_IS_LOADING", false);
    },
    /**
     * Permite agregar un objeto de orden de asamblea a la tabla de orden del día.
     */
    agregarOrdenManualAOrdenesdelDia(orden) {
      this.listaOrdenDelDia = this.listaOrdenDelDia.concat([orden]).unique();
    },
    /**
     * Permite remover un elemento de la tabla de ordenes.
     * @param orden {Object} Elemento de la tabla de ordenes.
     */
    deleteItemOrden(orden) {
      const index = this.listaOrdenDelDia.indexOf(orden);
      confirm("¿Está seguro que desea quitar la orden actual?") &&
        this.listaOrdenDelDia.splice(index, 1);
    },
    /**
     * Permite remover un elemento de la abla de invitados
     */
    deleteItemInvitado(item) {
      const index = this.invitados.indexOf(item);
      confirm("¿Está seguro que desea eliminar el invitado?") &&
        this.invitados.splice(index, 1);
    },
    /**
     * Almacena en memoría las mociones que se agregarán a la tabla de ordenes del día.
     */
    seleccionarTodasLasMocionesPorAsignar() {
      if (this.mocionesPorAsignarSeleccionadas.length)
        this.mocionesPorAsignarSeleccionadas = [];
      else
        this.mocionesPorAsignarSeleccionadas = this.mocionesPorAsignar.slice();
    },
    /**
     * Coloca las mociones elegidas en la tabla de órden del día
     */
    agregarMociones() {
      this.listaOrdenDelDia = this.listaOrdenDelDia
        .concat(this.mocionesPorAsignarSeleccionadas)
        .unique();

      this.dialogMociones = false;
    },
    /**
     * Realiza la validación de los campos para la asamblea.
     */
    validacionAsamblea() {
      this.$store.commit("CHANGE_IS_LOADING", true);
      // Valida que las tablas tengan al menos 1 registro
      if (this.usuariosJuntaDirectivaFinales.length === 0) {
        this.errMsg =
          "Debe existir al menos 1 integrante de la junta directiva.";
        this.snackbar = true;
        this.$store.commit("CHANGE_IS_LOADING", false);
        return false;
      }
      if (this.usuariosIntegrantesAdministracion.length === 0) {
        this.errMsg = "Debe existir al menos 1 integrante de administración.";
        this.snackbar = true;
        this.$store.commit("CHANGE_IS_LOADING", false);
        return false;
      }
      if (this.invitados.length === 0) {
        this.errMsg = "Debe existir al menos 1 invitado.";
        this.snackbar = true;
        this.$store.commit("CHANGE_IS_LOADING", false);
        return false;
      }
      if (this.listaOrdenDelDia.length === 0) {
        this.errMsg = "Debe existir al menos 1 registro en la Orden del día.";
        this.snackbar = true;
        this.$store.commit("CHANGE_IS_LOADING", false);
        return false;
      }
      // Valida la tabla orden del día que tenga Tiempo / Descisión
      for (let i = 0; i < this.listaOrdenDelDia.length; i++) {
        if (this.listaOrdenDelDia[i].TiempoVotacion === null) {
          this.errMsg =
            "No deje campos vacíos en la columna 'Tiempo/Descición' de la tabla de órdenes del día.";
          this.snackbar = true;
          this.$store.commit("CHANGE_IS_LOADING", false);
          return false;
        }
      }

      // Validaciones de veevalidate
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit("CHANGE_IS_LOADING", false);
        // Fecha asamblea
        if (!this.$v.edicionAsamblea.FechaAsamblea.required) {
          this.errMsg = "No deje el campo 'Fecha Asamblea' vacío";
          this.snackbar = true;
          return false;
        }
        // Hora de inicio
        if (!this.$v.edicionAsamblea.HoraInicio.required) {
          this.errMsg = "No deje el campo 'Hora de inicio' vacío";
          this.snackbar = true;
          return false;
        }
        // Primera Llamada
        if (
          !this.$v.edicionAsamblea.PrimeraLlamada.between ||
          !this.$v.edicionAsamblea.PrimeraLlamada.required
        ) {
          this.errMsg =
            "El campo 'Tiempo de la primera llamada', debe ser un valor entre 1 y 99";
          this.snackbar = true;
          return false;
        }
        // Segunda Llamada
        if (
          !this.$v.edicionAsamblea.SegundaLlamada.between ||
          !this.$v.edicionAsamblea.SegundaLlamada.required
        ) {
          this.errMsg =
            "El campo 'Tiempo de la segunda llamada', debe ser un valor entre 1 y 99";
          this.snackbar = true;
          return false;
        }
        return false;
      }
      return true;
    },
    /**
     * Método para la actualización de la asamblea.
     */
    async actualizarAsamblea() {
      if (!this.validacionAsamblea()) {
        return;
      }
      // Remueve el atributo Activo del objeto
      delete this.edicionAsamblea.Activo;
      /** Actualiza la asamblea */
      let responseEdicionAsamblea = await axios.patch(
        `${process.env.VUE_APP_BASE_URL}/asamblea/${this.IdAsamblea}`,
        this.edicionAsamblea
      ); /** /Genera la CveAsamblea */

      responseEdicionAsamblea = responseEdicionAsamblea.data.payload;

      // Asocia los integrantes de la junta directiva
      this.usuariosJuntaDirectivaFinales.forEach(async (element) => {
        let nuevoIntegranteJuntaDirectiva = {
          IdAsamblea: responseEdicionAsamblea.IdAsamblea,
          IdUsuario: element.IdUsuario,
        };
        await axios.post(
          `${process.env.VUE_APP_BASE_URL}/integrante`,
          nuevoIntegranteJuntaDirectiva
        );
      });

      // Crea los integnrates de administración
      this.usuariosIntegrantesAdministracion.forEach(async (element) => {
        element.IdAsamblea = responseEdicionAsamblea.IdAsamblea;
        await axios.post(`${process.env.VUE_APP_BASE_URL}/invitado`, element);
      });

      // Crea los invitados
      this.invitados.forEach(async (element) => {
        element.IdAsamblea = responseEdicionAsamblea.IdAsamblea;
        await axios.post(`${process.env.VUE_APP_BASE_URL}/invitado`, element);
      });

      // Asocia las mociones a la asamblea
      this.listaOrdenDelDia.forEach(async (element) => {
        // Actualiza por tipo de orden
        let updateMociones = {};
        let updateOrden = {};

        switch (element.Tipo) {
          case this.tipoOrden.MOCION:
            updateMociones = {
              TiempoVotacion: element.TiempoVotacion,
              OrdenAsamblea: element.OrdenAsamblea,
              IdAsamblea: responseEdicionAsamblea.IdAsamblea,
            };
            await axios.patch(
              `${process.env.VUE_APP_BASE_URL}/mocion/${element.IdMocion}`,
              updateMociones
            );
            break;
          case this.tipoOrden.ORDEN_MANUAL:
            updateOrden = {
              TiempoVotacion: element.TiempoVotacion,
              OrdenAsamblea: element.OrdenAsamblea,
              IdAsamblea: responseEdicionAsamblea.IdAsamblea,
            };
            await axios.patch(
              `${process.env.VUE_APP_BASE_URL}/orden/${element.IdOrden}`,
              updateOrden
            );
            break;
        }
      });

      // Cierra ell modal de la asamblea.
      this.loadInitialData();
      this.dialog = false;
      this.$store.commit("CHANGE_IS_LOADING", false);
    },
  },
};
</script>

<style lang="css">
.invitados-container .v-toolbar__content {
  background-color: rgba(0, 0, 0, 0) !important;
}
.cursor-move {
  cursor: move;
}
</style>
