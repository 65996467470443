import {mapGetters} from 'vuex'

export default {
    name: 'Perfil',
    components: {

    }, data: () => ({
		defaultImage:
			"https://images.unsplash.com/photo-1470549813517-2fa741d25c92?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80",
		gradient: "to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
	}),
      computed: {
        // convert the list of events into a map of lists keyed by date
        ...mapGetters({
          authenticated: 'auth/authenticated',
          user: 'auth/user',
        })
      },
      methods: {
       
      }
}