import Vue from "vue";
import Vuex from "vuex";
import padron from "./modules/padron";
import mociones from "./modules/mociones";
import asambleas from "./modules/asambleas";
import estatus from "./modules/estatus";
import auth from "./modules/auth";
import adduser from "./modules/adduser";
import asambleacurso from "./modules/asambleacurso";
import asambleacursomodal from "./modules/asambleacursomodal";
import encuestas from "./modules/encuestas";
import elecciones from "./modules/elecciones";
import preguntas from "./modules/preguntas";
import tema from "./modules/tema";
import votaciones from "./modules/votaciones";

//import axios from 'axios'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    padron,
    mociones,
    asambleas,
    estatus,
    auth,
    adduser,
    asambleacurso,
    asambleacursomodal,
    encuestas,
    elecciones,
    preguntas,
    tema,
    votaciones
  },
  state: {
    isLoading: false,
    io: {}
  },
  mutations: {
    CHANGE_IS_LOADING: (state, val) => {
      state.isLoading = val;
    },
    SET_SOCKET: (state, socket) => {
      state.io = socket;
     // console.log('socket conectado');
    },
  },
  actions: {},
  getters: {}
});
