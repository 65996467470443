import addasambleas from "@/components/addasambleas.vue";
import editasambleas from "@/components/editasambleas.vue";
import jsPDF from "jspdf";
import axios from "axios";

export default {
  name: "asambleas",
  components: {
    addasambleas,
    editasambleas
  },
  created() {
    this.buscarAsambleas();
  },
  data() {
    return {
      search: "",
      searchAsambleaTxt: "",
      pagination: {
        rowsPerPage: 10
      },
      headers: [
        {
          text: "",
          align: "left",
          sortable: false,
          value: "id"
        },
        {
          text: "Asamblea",
          align: "left",
          sortable: true,
          value: "CveAsamblea"
        },
        { text: "Fecha", value: "FechaAsamblea", sortable: true },
        {
          text: "Registrado Por",
          value: "Ct_Usuario.nombreCompleto",
          sortable: true
        },
        { text: "Estado", value: "Ct_Estado.Estado", sortable: true },
       
      ],
      forpdf: [
        {
          asamblea: "Revisión de estados financieros",
          id: "1",
          fecha: "27/11/2020",
          register: "Julio Melgar",
          estado: "Cancelada"
        },
        {
          asamblea: "Asamblea 250",
          id: "2",
          fecha: "27/11/2020",
          register: "Julio Melgar",
          estado: "Cancelada"
        },
        {
          asamblea: "Asamblea 846",
          id: "3",
          fecha: "27/11/2020",
          register: "Julio Melgar",
          estado: "Cancelada"
        }
      ],
      asambleas: []
    };
  },
  methods: {
    async createPDF(IdAsamblea) {
      // Obtiene la información de la asamblea a imprimir en el PDF.
      let resAsamblea = await axios.get(
        `${process.env.VUE_APP_BASE_URL}/asamblea/${IdAsamblea}`
      );
      resAsamblea = resAsamblea.data.payload.asamblea;
      var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "v" });
      doc.text("Reporte de Administración de Asambleas", 10, 15);
      doc.text(`CveAsamblea: ${resAsamblea.CveAsamblea}`, 10, 30);
      doc.text(`FechaAsamblea: ${resAsamblea.FechaAsamblea}`, 10, 40);
      doc.text(`Hora Inicio: ${resAsamblea.HoraInicio}`, 10, 50);
      doc.text(
        `Hora Fin: ${resAsamblea.HoraFin !== null ? resAsamblea.HoraFin : ""}`,
        10,
        60
      );
      doc.text(`Primera llamada: ${resAsamblea.PrimeraLlamada}`, 10, 70);
      doc.text(`Segunda llamada: ${resAsamblea.SegundaLlamada}`, 10, 80);

      doc.text(`Estado: ${resAsamblea.Ct_Estado.Estado}`, 10, 90);

      doc.save("asambleas.pdf");
    },
    buscarAsambleas() {
      this.$store.commit("CHANGE_IS_LOADING", true);
      // Carga la lista de asambleadas existentes)
      axios
        .get(
          process.env.VUE_APP_BASE_URL +
            `/asamblea/?search=${this.searchAsambleaTxt}`
        )
        .then(response => {
          this.asambleas = response.data.payload.asambleas;
          this.$store.commit("CHANGE_IS_LOADING", false);
        });
    }
  },
  watch: {
    asambleas(val) {
      this.pagination.totalItems = val.length;
    }
  },
  computed: {
    headerTableStyles() {
      let color = localStorage.getItem("tema");
      return {
        "--bg-color": color
      };
    },
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    }
  }
};
