<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="primary" v-bind="attrs" v-on="on">
        Crear Encuesta
        <v-icon right dark>fa fa-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Crear Encuesta </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader></v-subheader>
        <div class="list-item-mod">
          <v-app id="inspire">
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      disabled
                      label="Cve. Encuesta"
                      outline
                      v-model="newTest.CveEncuesta"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md3>
                    <datepicker
                      :inactive="true"
                      labelpiker="Fecha Registro"
                      :date="newTest.FechaRegistro"
                      @input="newTest.FechaRegistro = $event"
                    ></datepicker>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <!--  <v-select
                      :items="asamblea_state(estados)"
                      label="Estado"
                      outline
                    >
                    </v-select> -->
                    <v-text-field
                      disabled
                      label="Estado:"
                      outline
                      value="EN CURSO"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      disabled
                      label="Registrado Por:"
                      outline
                      :value="user.Nombre1"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container>
                <v-subheader>Descripción</v-subheader>
                <hr />
                <vue-editor v-model="newTest.Encuesta" />
              </v-container>

              <v-container class="seccion-tabla">
                <v-layout row>
                  <v-flex xs12 sm6 md6 class="mx-auto text-xs-center">
                    <div class="texto-borde">
                      <v-subheader class="mx-auto text-xs-center"
                        >Opciones a considerar:</v-subheader
                      >

                      <ul>
                        <li
                          v-for="(inputss, index) in newTest.Opciones"
                          :key="index"
                        >
                          <input
                            class="background-color:red"
                            type="text"
                            v-model="inputss.Opcion"
                          />
                          <v-btn
                            class="error-red white--text"
                            @click="deleteRow(index)"
                            >Delete</v-btn
                          >
                          <br />
                        </li>
                      </ul>
                      <br />
                      <v-btn class="info-blue white--text" @click="addRow()"
                        >Agregar Opcion</v-btn
                      >
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>

              <v-container class="d-flex justify-space-between">
                <v-flex xs12 sm6 md3 class="mx-auto text-xs-center">
                  <v-btn class="primary" @click="saveTest(user.IdUsuario)">
                    Guardar
                  </v-btn>
                </v-flex>
              </v-container>
            </v-form>
          </v-app>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>

  <script>
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import datepicker from "@/components/datepicker.vue";
export default {
  name: "addencuesta",
  components: {
    datepicker,
  },
  mounted() {
    this.createCveEncuesta();
  },
  data() {
    return {
      forGetIdState: null,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      e7: [],
      inputss: [],
      tableRows: [],
      counts: 0,
      options: [],
      newTest: {
        CveEncuesta: "",
        FechaRegistro: "",
        IdEstado: "13",
        Encuesta: "",
        IdUsuario: null,
        IdAsamblea: "5186",
        Quorum: "0",
        OrdenAsamblea: 0,
        TiempoVotacion: 0,
        HoraInicio: "00:00:00",
        HoraFin: "00:00:00",
        TpVoto: "",
        Opciones: [],
      },
    };
  },
  methods: {
    async createCveEncuesta() {
      let responseConsecutivo = await axios.get(
        process.env.VUE_APP_BASE_URL + `/pgeneral/3`
      );
      responseConsecutivo = responseConsecutivo.data.payload;

      this.consecutivo = parseInt(responseConsecutivo[0].Valor) + 1;

      var consecutivo;
      if (responseConsecutivo[0].Valor.length == 1) {
        consecutivo = "000" + this.consecutivo;
      }
      if (responseConsecutivo[0].Valor.length == 2) {
        consecutivo = "00" + this.consecutivo;
      }
      if (responseConsecutivo[0].Valor.length == 3) {
        consecutivo = "0" + this.consecutivo;
      }
      if (responseConsecutivo[0].Valor.length == 4) {
        consecutivo = this.consecutivo;
      }
      var dt = new Date();
      var actualMonth = dt.getMonth() + 1;
      this.newTest.CveEncuesta =
        "EN-" + dt.getFullYear() + "-" + actualMonth + "-" + consecutivo + 5;
    },
    getIdEstado(estados) {
     // console.log(this.forGetIdState);
      let stateGeted;
      for (let i = 0; i < estados.length; i++) {
        if (estados[i].Estado == this.forGetIdState) {
          console.log(estados[i].IdEstado);
          stateGeted == estados[i].IdEstado;
        }
      }
      console.log(stateGeted);
    },
    asamblea_state(estados) {
      let getEstados = estados.map((estado) => {
        return estado.Estado;
      });
      return getEstados;
    },
    async saveTest(iduser) {
      let upConsecutivo = {
        Valor: this.consecutivo.toString(),
      };
      await axios.patch(
        process.env.VUE_APP_BASE_URL + `/pgeneral/3`,
        upConsecutivo
      );
      this.newTest.IdUsuario = iduser;
      /* var estadoId = "13"; */
      /*      this.newMotion.IdEstado = estadoId; */

      /* alert(
        " mensaje" +
          this.newTest.IdEstado +
          "*" +
          this.newTest.CveEncuesta +
          "*" +
          this.newTest.FechaRegistro +
          "*" +
          this.newTest.IdUsuario +
          "*" +
          this.newTest.Encuesta +
          "*" +
          this.newTest.IdAsamblea
      ); */
      console.log(this.newTest);
      await axios
        .post(process.env.VUE_APP_BASE_URL + `/encuestas`, this.newTest)
        .then(() => {
          alert("Encuesta Agregada con exito");

          this.newTest.CveEncuesta = "";
          this.newTest.Encuesta = "";
          this.createCveEncuesta();

          this.dialog = false;
          this.$store.dispatch("encuestas/fetchencuestas");
        })
        .catch((err) => {
          alert("Error del sistema, por favor contacte a su administrador");
          console.log(err);
        });
    },
    addRow() {
      this.counts++;
      this.newTest.Opciones.push({
        secuencia: this.counts,
        Opcion: this.inputss.Opcion,
      });
    },
    deleteRow(index) {
      this.newTest.Opciones.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    ...mapState("encuestas", ["estados"]),
  },
};
</script>


<style scoped>
.titulo-bg {
  background-color: #2196f3;
  color: white;
}
.texto-borde {
  border-width: 5px;
  border-style: solid;
  border-color: #32b294;
  border-radius: 20px;
  padding: 5px;
}

.seccion-tabla {
  width: 100%;
  align-content: center;
}
</style>
