import {mapActions} from "vuex";
import VueLoadingButton from "vue-loading-button";

export default {
    data(){
      return {
        isLoading: false,
        isStyled: false,
        errors: [],
        form:{
          email : null,
          password :null
        }
       
      }
    },
    methods: {
       ...mapActions({
         signIn: 'auth/signIn'
       }),
       submit(){
        if (this.form.email && this.form.password) {
          this.isLoading = true;
          this.signIn(this.form).then(()=>{
            this.$router.replace({
              name: 'home'
            })
          }).catch(()=>{
            this.isLoading = false
            console.log('fail');
            this.errors.push('Usuario o contraseña incorrectos.');
          })
        }
        this.errors = [];

        if (!this.form.email) {
          this.errors.push('Email requerido.');
        }
        if (!this.form.password) {
          this.errors.push('Password requerdio.');
        } 
        
       }
      },
      components: {
        VueLoadingButton
      }
  }