
export default {
    name:"Voting Result",
    components: {
       
      },
    data(){
        return{
            votantes_show:false,
            options: {
                labels: ['Postulante 1', 'Postulante 2'],
            },
            series: [40,60],
            headers: [
                { text: "Nombre", align: "left", sortable: true, value: "name" },
                { text: "Rol", align: "left", sortable: false },
                { text: "Comentarios", align: "left", sortable: false }
              ],
              item2s: [
                { id: "01", name: "Richardson Nogueira", age: "25", country: "Brazil" },
                { id: "02", name: "José Carlos", age: "52", country: "Brazil" },
                { id: "03", name: "Peter Here", age: "32", country: "Canada" },
                { id: "04", name: "Magnos Carlsen", age: "30", country: "EUA" }
              ]
        }
    }
}