import axios from 'axios'
export default {
  namespaced: true,
  state: {
    posts: [],
    estatus: [
      {
        avatar: 'https://randomuser.me/api/portraits/men/85.jpg',
        nombre: 'Frozen Yogurt',
        puesto: '10/10/2019',
        correo: 'Cancelada',
        estado: 'activo'
      },
      {
        avatar: 'https://randomuser.me/api/portraits/men/85.jpg',
        nombre: 'Frozen Yogurt',
        puesto: '10/10/2019',
        correo: 'Cancelada',
        estado: 'inactivo'
      },
      {
        avatar: 'https://randomuser.me/api/portraits/men/85.jpg',
        nombre: 'Frozen Yogurt',
        puesto: '10/10/2019',
        correo: 'Cancelada',
        estado: 'cancelado'
      }
    ]
  },

  mutations: {
    SET_ASAMBLEA(state, posts) {
      state.posts = posts
    },
    DELETE_USER(state, idvideo) {
      //console.log('idvid' + idvideo)
      let posts = state.posts.filter(v => v.IdUsuario != idvideo)
      state.posts = posts;
    },
  },
  actions: {
    async fetchasambleas({ dispatch }) {
      let response = await axios.get(process.env.VUE_APP_BASE_URL + '/usuarios')
      //console.log(credentials);
      return dispatch('attempt', response.data.payload)

    },
    async attempt({ commit, state }, posts) {
      if (posts) {
        commit('SET_ASAMBLEA', posts)
      }
      if (!state.posts) {

        return
      }


    },
    async desactivar({ commit }, idvideo) {
     // console.log(idvideo)
      let response = await axios.delete(process.env.VUE_APP_BASE_URL + '/usuarios/' + idvideo)
      commit('DELETE_USER', idvideo)
      console.log(response.data.payload.msg)
    }
  },
  getters: {
    asambleasget(state) {
      return state.posts
    },
  }

}