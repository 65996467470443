//import { mapState } from "vuex";
//import jsPDF from "jspdf";

/*export default {
  name: "Home",
  components: {},
  data() {
    return {
      output: null,
      radioGroup: 1,
      search: "",
      pagination: {},
      admin: [],
      presidente: [],
      principal: [],
      voto: [],
      voz: [],
      headers: [
        { text: "", value: "menu", sortable: false, class: "primary" },
        {
          text: "Tipo Padron",
          align: "left",
          sortable: false,
          value: "padron",
          class: "primary"
        },
        {
          text: "Junta Dir",
          value: "directiva",
          sortable: false,
          class: "primary"
        },
        {
          text: "Administrativo",
          value: "admin",
          sortable: false,
          class: "primary"
        },
        {
          text: "Agremiado",
          value: "agremiado",
          sortable: false,
          class: "primary"
        },
        { text: "Voto", value: "vote", sortable: false, class: "primary" },
        { text: "Voz", value: "voice", sortable: false, class: "primary" },
        { text: "Estado", value: "option", sortable: false, class: "primary" }
      ],
      forpdf: [
        {
          padron: "Presidente",
          id: "1",
          directiva: "No",
          admin: "No",
          agremiado: "Si",
          vote: "No",
          voice: "Si",
          state: "Cancelada"
        },
        {
          padron: "Administrativo",
          id: "2",
          directiva: "No",
          admin: "No",
          agremiado: "Si",
          vote: "No",
          voice: "Si",
          state: "Cancelada"
        },
        {
          padron: "Presidente(a)",
          id: "3",
          directiva: "No",
          admin: "No",
          agremiado: "Si",
          vote: "No",
          voice: "Si",
          state: "Cancelada"
        },
        {
          padron: "Secretario(a)",
          id: "4",
          directiva: "No",
          admin: "No",
          agremiado: "Si",
          vote: "No",
          voice: "Si",
          state: "Cancelada"
        },
        {
          padron: "Agremiado",
          id: "5",
          directiva: "No",
          admin: "No",
          agremiado: "Si",
          vote: "No",
          voice: "Si",
          state: "Cancelada"
        }
      ]
    };
  },
  methods: {
    createPDF() {
      var headers2 = [
        {
          id: "padron",
          name: "padron",
          prompt: "Tipo de Padron",
          width: 50,
          color: "#fff",
          align: "center",
          padding: 5
        },
        {
          id: "directiva",
          name: "directiva",
          prompt: "Junta Dir",
          width: 45,
          align: "center",
          padding: 5
        },
        {
          id: "admin",
          name: "admin",
          prompt: "Administrativo",
          width: 45,
          align: "center",
          padding: 5
        },
        {
          id: "agremiado",
          name: "agremiado",
          prompt: "Agremiado",
          width: 45,
          align: "center",
          padding: 5
        },
        {
          id: "vote",
          name: "vote",
          prompt: "Voto",
          width: 45,
          align: "center",
          padding: 5
        },
        {
          id: "voice",
          name: "voice",
          prompt: "Voz",
          width: 45,
          align: "center",
          padding: 5
        },
        {
          id: "state",
          name: "state",
          prompt: "Estado",
          width: 45,
          align: "center",
          padding: 5
        }
      ];

      var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "l" });
      doc.text("Catalogo de Tipos de Padron", 95, 40);
      doc.table(25, 50, this.forpdf, headers2, {
        headerBackgroundColor: "#8DAF0B",
        autoSize: false,
        format: "a6",
        text: "#fff"
      });
      doc.save("padron.pdf");
    },
    which(padron, id) {
      var admin = "admin-" + id;
      var agremiado = "agremiado-" + id;
      var directiva = "directiva-" + id;
      var voice = "voice-" + id;
      var vote = "vote-" + id;
      if (padron === "directiva") {
        if (this.$refs[directiva].checked == false) {
          this.$refs[admin].removeAttribute("disabled");
          this.$refs[agremiado].removeAttribute("disabled");
          this.$refs[directiva].removeAttribute("disabled");

          if (this.$refs[voice].checked == true) {
            this.$refs[voice].click();
          }

          if (this.$refs[vote].checked == true) {
            this.$refs[vote].click();
          }

          this.$refs[voice].setAttribute("disabled", "true");

          this.$refs[vote].setAttribute("disabled", "true");
        }
        if (this.$refs[directiva].checked == true) {
          this.$refs[admin].setAttribute("disabled", "true");
          this.$refs[agremiado].setAttribute("disabled", "true");
          this.$refs[directiva].removeAttribute("disabled");
          this.$refs[voice].removeAttribute("disabled");
          this.$refs[vote].removeAttribute("disabled");
        }
      }

      if (padron === "admin") {
        if (this.$refs[admin].checked == false) {
          this.$refs[admin].removeAttribute("disabled");
          this.$refs[agremiado].removeAttribute("disabled");
          this.$refs[directiva].removeAttribute("disabled");
          if (this.$refs[voice].checked == true) {
            this.$refs[voice].click();
          }

          if (this.$refs[vote].checked == true) {
            this.$refs[vote].click();
          }

          this.$refs[voice].setAttribute("disabled", "true");
          this.$refs[vote].setAttribute("disabled", "true");
        }
        if (this.$refs[admin].checked == true) {
          this.$refs[directiva].setAttribute("disabled", "true");
          this.$refs[agremiado].setAttribute("disabled", "true");
          this.$refs[admin].removeAttribute("disabled");
          this.$refs[voice].removeAttribute("disabled");
          this.$refs[vote].removeAttribute("disabled");
        }
      }

      if (padron === "agremiado") {
        if (this.$refs[agremiado].checked == false) {
          this.$refs[admin].removeAttribute("disabled");
          this.$refs[agremiado].removeAttribute("disabled");
          this.$refs[directiva].removeAttribute("disabled");

          if (this.$refs[vote].checked == true) {
            this.$refs[vote].click();
          }
          this.$refs[vote].setAttribute("disabled", "true");
        }
        if (this.$refs[agremiado].checked == true) {
          this.$refs[directiva].setAttribute("disabled", "true");
          this.$refs[admin].setAttribute("disabled", "true");
          this.$refs[agremiado].removeAttribute("disabled");
          this.$refs[vote].removeAttribute("disabled");
        }
      }
    }
  },
  computed: {
    VoiceDisabled() {},
    VoteDisabled() {},
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },

    ...mapState("padron", ["desserts"])
  }
}; */
import axios from "axios";
import addpadron from "@/components/addpadron.vue";
import editpadron from "@/components/editpadron.vue";
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
  name: 'padron',
  components: {
    addpadron,
    'editpadron': editpadron
  },
  data() {
    return {
      Voztrue: true,
      Vozfalse: false,
      Vototrue: true,
      Votofalse: false,
      Admintrue: true,
      Adminfalse: false,
      filteredProducts: [],
      search: '',
      pagination: {},
      selected: [],
      headers: [
        { text: 'Acciones', value: 'actions', sortable: false, class: "primary" },
        { text: 'Padron', value: 'TpPadron', sortable: false, class: "primary" },
        {
          text: 'Voz',
          align: 'center',
          sortable: false,
          value: 'Voz',
          class: "primary"
        },
        { text: 'Voto', value: 'Voto', sortable: false, class: "primary" },
        { text: 'Admin', value: 'Admin', sortable: false, class: "primary" },
        { text: 'Agremiado', value: 'Agremiado', sortable: false, class: "primary" },
        { text: 'Junta Directiva', value: 'Junta', sortable: false, class: "primary" },
        { text: 'Estado', value: 'Activo', sortable: false, class: "primary" },
      ],

    }
  },
  async mounted() {
    await this.$store.dispatch("padron/fetchpadrones");
    this.filteredProducts = this.getpadron.filter(product => product.Activo.includes('S'))

  },
  computed: {
    ...mapGetters({
      getpadron: 'padron/padronesget',
    }),
    ...mapActions({
      delete: 'padron/desactivar'
    }),
    // ...mapActions({
    //  delete: 'estatus/desactivar'
    // }),

    pages() {
      if (this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) return 0

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    },
  },
  methods: {
    change1(index, value) {

      //console.log(index, value)
      axios
        .patch(
          process.env.VUE_APP_BASE_URL +
          `/padron/` + index,
          { Voz: value },
        )
        .then(resp => {
          console.log(resp);
          //this.imagePath = resp.data.path;
          alert("Permiso cambiado con exito");
          //localStorage.setItem("tema", this.vaqlores.Valor);
          //window.location.reload();
        })
        .catch(() => {
          console.log("fail");
          alert("Error al cambiar permiso, intntelo de nuevo mas tarde ");
        });
    },
    change2(index, value) {

      //console.log(index, value)
      axios
        .patch(
          process.env.VUE_APP_BASE_URL +
          `/padron/` + index,
          { Voto: value },
        )
        .then(resp => {
          console.log(resp);
          //this.imagePath = resp.data.path;
          alert("Permiso cambiado con exito");
          //localStorage.setItem("tema", this.vaqlores.Valor);
          //window.location.reload();
        })
        .catch(() => {
          console.log("fail");
          alert("Error al cambiar permiso, intntelo de nuevo mas tarde ");
        });
    },
    change3(index, value) {

      //console.log(index, value)
      axios
        .patch(
          process.env.VUE_APP_BASE_URL +
          `/padron/` + index,
          { Admin: value },
        )
        .then(resp => {
          console.log(resp);
          //this.imagePath = resp.data.path;
          alert("Permiso cambiado con exito");
          //localStorage.setItem("tema", this.vaqlores.Valor);
          //window.location.reload();
        })
        .catch(() => {
          console.log("fail");
          alert("Error al cambiar permiso, intntelo de nuevo mas tarde ");
        });
    },
    change4(index, value) {

      //console.log(index, value)
      axios
        .patch(
          process.env.VUE_APP_BASE_URL +
          `/padron/` + index,
          { Agremiado: value },
        )
        .then(resp => {
          console.log(resp);
          //this.imagePath = resp.data.path;
          alert("Permiso cambiado con exito");
          //localStorage.setItem("tema", this.vaqlores.Valor);
          //window.location.reload();
        })
        .catch(() => {
          console.log("fail");
          alert("Error al cambiar permiso, intntelo de nuevo mas tarde ");
        });
    },
    change5(index, value) {

      //console.log(index, value)
      axios
        .patch(
          process.env.VUE_APP_BASE_URL +
          `/padron/` + index,
          { Junta: value },
        )
        .then(resp => {
          console.log(resp);
          //this.imagePath = resp.data.path;
          alert("Permiso cambiado con exito");
          //localStorage.setItem("tema", this.vaqlores.Valor);
          //window.location.reload();
        })
        .catch(() => {
          console.log("fail");
          alert("Error al cambiar permiso, intntelo de nuevo mas tarde ");
        });
    },

    deleteItem(item) {

      confirm('¿Seguro que desea desactivar al usuario seleccionado?') && this.$store.dispatch('padron/desactivar', item);
    },

  },
  watch: {

  },


}