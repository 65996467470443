import { mapGetters } from 'vuex'
import addmocion from "@/components/addmocion.vue";
import addpregunta from "@/components/addpregunta.vue";
import votacionpuesto from "@/components/votacion-puesto.vue";
import votacionmociones from "@/components/addejercevotacion.vue";
import votacionencuesta from "@/components/votacionencuesta.vue";
import conferencia from "@/components/conferencia.vue"

export default {
  name: 'Home',
  components: {
    addmocion,
    addpregunta,
    votacionpuesto,
    votacionmociones,
    votacionencuesta,
    conferencia
  }, data: () => ({
    seen: true,
    valueDeterminate: 50,
    image: require('@/assets/b1.jpg'),
    image2: require('@/assets/b2.jpg'),
    image3: require('@/assets/b3.jpg'),
    image4: require('@/assets/b4.jpg'),
    image5: require('@/assets/b5.jpg'),
    image6: require('@/assets/b6.jpg'),
    ocultar: "Ocultar",
    icons: "fa fa-chevron-up",
    today: '2019-01-08',
    startTime: '12:00',
    endTime: '23:59',
    events: [
      {
        title: 'Vacation',
        details: 'Going to the beach!',
        date: '2018-12-30',
        open: false
      },
      {
        title: 'Vacation',
        details: 'Going to the beach!',
        date: '2018-12-31',
        open: false
      },
      {
        title: 'Vacation',
        details: 'Going to the beach!',
        date: '2019-01-01',
        open: false
      },
      {
        title: 'Meeting',
        details: 'Spending time on how we do not have enough time',
        date: '2019-01-07',
        open: false
      },
      {
        title: '30th Birthday',
        details: 'Celebrate responsibly',
        date: '2019-01-03',
        open: false
      },
      {
        title: 'New Year',
        details: 'Eat chocolate until you pass out',
        date: '2019-01-01',
        open: false
      },
      {
        title: 'Conference',
        details: 'Mute myself the whole time and wonder why I am on this call',
        date: '2019-01-21',
        open: false
      },
      {
        title: 'Hackathon',
        details: 'Code like there is no tommorrow',
        date: '2019-02-01',
        open: false
      }
    ]
  }),
  created: function () {
    this.today = new Date();
    var dd = String(this.today.getDate()).padStart(2, '0');
    var mm = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = this.today.getFullYear();
    this.today = mm + '/' + dd + '/' + yyyy;

    this.getSession()
  },
  computed: {
    // convert the list of events into a map of lists keyed by date
    eventsMap() {
      const map = {}
      this.events.forEach(e => (map[e.date] = map[e.date] || []).push(e))
      return map
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      usuario: 'auth/user',
    })
  },
  methods: {
    open(event) {
      alert(event.title)
    },
    show1() {
      if (this.seen == false) {
        this.seen = true
        this.ocultar = "Ocultar"
        this.icons = "fa fa-chevron-up"
      }

      else {
        this.seen = false
        this.ocultar = "Mostrar"
        this.icons = "fa fa-chevron-down"
      }

    },
    getSession() {

      if (localStorage.getItem("Qses") === null) {
        localStorage.setItem("Qses", 1)
        this.$socket.emit('QUORUMCOUNT', {
          name: this.usuario.Nombre1 + ' ' + this.usuario.PrimerApellido,
          country: this.usuario.Ct_TpPadron.TpPadron,
          id: this.usuario.IdUsuario,
        });

      }

    }
  },
  
}