export const API = {
    // AUTH
    login: `${process.env.VUE_APP_BASE_URL}/login`,
    signup: `${process.env.VUE_APP_BASE_URL}/singup`,
    changePassword: `${process.env.VUE_APP_BASE_URL}/cambiar-contrasena`,
    restartPassword: `${process.env.VUE_APP_BASE_URL}/restart-password`,
    // USERS
    users: `${process.env.VUE_APP_BASE_URL}/usuarios`,
    userPhoto: `${process.env.VUE_APP_BASE_URL}/usuarios-update-foto-perfil`,
    // ZOOM
    zoom: `${process.env.VUE_APP_BASE_URL}/zoom`,
    // EMAIL
    email: `${process.env.VUE_APP_BASE_URL}/email`,
    // ASAMBLEAS
    asamblea: `${process.env.VUE_APP_BASE_URL}/asamblea?IdEstado=1`
}