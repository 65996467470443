<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" v-bind="attrs" v-on="on">
        Crear Elección
        <v-icon right dark>fa fa-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false"> </v-btn>
        <v-toolbar-title>Crear Elección </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn color="primary" flat text @click="dialog = false">
            Guardar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list three-line subheader>
        <!-- <v-subheader></v-subheader> -->
        <div class="list-item-mod">
          <v-app id="inspire">
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm6 md3>
                    <v-text-field label="Cve. Elección" outline></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <datepicker labelpiker="Fecha Registro"></datepicker>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select
                      :items="asamblea_state"
                      label="Estado"
                      outline
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field label="Registrado por" outline></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container>
                <v-subheader>Descripción:</v-subheader>
                <hr />
                <vue-editor v-model="content" />
              </v-container>
              <v-container>
                <div id="app">
                  <v-container class="seccion-tabla">
                    <v-layout row>
                      <v-flex xs12 sm6 md6 class="mx-auto text-xs-center">
                        <div class="texto-borde">
                          <v-subheader class="mx-auto text-xs-center"
                            >Opciones a considerar:</v-subheader
                          >
                          <table class="formato-tabla mx-auto text-xs-center">
                            <tr
                              v-for="(content, index) in tableRows"
                              :key="index"
                            >
                              <td>
                                {{ content }}
                              </td>
                              <td>
                                <v-combobox outlined></v-combobox>
                              </td>
                              <td>
                                <button @click="deleteTableRow(index)">
                                  <v-icon>fa fa-trash</v-icon>
                                </button>
                              </td>
                            </tr>
                          </table>

                          <v-btn
                            @click="addTableRow()"
                            color="successs"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <!--  <v-icon right dark>fa fa-plus</v-icon> -->
                            Agregar opción
                          </v-btn>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </div></v-container
              >

              <div></div>
            </v-form>
          </v-app>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>






  <script>
import datepicker from "@/components/datepicker.vue";
export default {
  name: "addeleccion",
  components: {
    datepicker,
  },
  data() {
    return {
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      e7: [],
      asamblea_state: [
        "PREVIO",
        "EN CURSO",
        "CANCELADA",
        "INACTIVA",
        "CONCLUIDA",
      ],
      states: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",

        "Wyoming",
      ],
      tableRows: [],
      counter: 0,
    };
  },
  methods: {
    addTableRow: function () {
      this.counter++;
      this.tableRows.push("#" + this.counter + ":");
    },
    deleteTableRow: function (idx) {
      this.counter--;
      this.tableRows.splice(idx, 1);
    },
  },
};
</script>

<style scoped>
.titulo-bg {
  background-color: #2196f3;
  color: white;
}
.texto-borde {
  border-width: 5px;
  border-style: solid;
  border-color: #32b294;
  border-radius: 20px;
  padding: 5px;
}

.seccion-tabla {
  width: 70%;
  align-content: center;
}
</style>