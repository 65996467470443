import axios from "axios";

export default {
  namespaced: true,
  state: {
    encuestas: [],
    estados: []
  },
  mutations: {
    SET_ENCUESTAS(state, encuesta) {
      state.encuestas = encuesta;
    },
    SET_STATES(state, estados) {
      state.estados = estados;
    }
  },
  actions: {
    async fetchencuestas({ dispatch }) {
      let response = await axios.get(
        process.env.VUE_APP_BASE_URL + "/encuestas"
      );
      return dispatch("attemptencuestas", response.data.payload);
    },
    async attemptencuestas({ commit, state }, encuesta) {
      console.log(encuesta);
      if (encuesta) {
        commit("SET_ENCUESTAS", encuesta);
      }
      if (!state.encuestas) {
        return;
      }
    },
    async fetchestados({ dispatch }) {
      let response = await axios.get(
        process.env.VUE_APP_BASE_URL + "/estado/?Catalo=ENCUESTAS"
      );
      return dispatch("attemptestados", response.data.payload);
    },
    async attemptestados({ commit, state }, estados) {
      if (estados) {
        commit("SET_STATES", estados);
      }
      if (!state.estados) {
        return;
      }
    }
  }
};
