import axios from "axios";
export default {

  name: 'settings',
  components: {

  }, data() {
    return {
      FILE: null,
      vaqlores: {
        Valor: null
      },
      perfil: {
        Valor: null
      }
    }
  },
  computed: {

  },
  methods: {
    onFileUpload(event) {
      this.FILE = event.target.files[0];
      //console.log(this.FILE);
    },
    submit() {
      const formData = new FormData();
      formData.append("Valor", this.FILE, this.FILE.name);
      console.log('imaggeeeenn' + this.FILE.name)
      this.perfil.Valor = formData
      axios
        .patch(
          process.env.VUE_APP_BASE_URL +
          `/pgeneral/7/upload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(resp => {
          console.log(resp);
          //this.imagePath = resp.data.path;
          alert("Logo Agregado con exito");
          //window.location.reload();
        })
        .catch(() => {
          console.log("fail");
          alert("Error al crear Logo, intntelo de nuevo mas tarde ");
        });
    },
    changecolor(color) {
      console.log(color)
      this.vaqlores.Valor = color

      axios
        .patch(
          process.env.VUE_APP_BASE_URL +
          `/pgeneral/8`,
          this.vaqlores,
        )
        .then(resp => {
          console.log(resp);
          //this.imagePath = resp.data.path;
          alert("tema cambiado con exito");
          localStorage.setItem("tema", this.vaqlores.Valor);
          window.location.reload();
        })
        .catch(() => {
          console.log("fail");
          alert("Error al cambiar tema, intntelo de nuevo mas tarde ");
        });

    }
  }
}