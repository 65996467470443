import axios from 'axios'

export default ({
  namespaced: true,
  state: {
    padron: [],
    edit:[]

  },
  mutations: {
    SET_PADRON(state, padron) {
      state.padron = padron
    },
    SET_ADDPADRON(state, data) {
      state.padron = data
    },
    SET_EDITPADRON(state, edit) {
      state.padron = edit
    },
    DELETE_PADRON(state, idvideo) {
      //console.log('idvid' + idvideo)
      let padron = state.padron.filter(v => v.IdPadron != idvideo)
      state.padron = padron;
    },
  },
  actions: {
    async fetchpadrones({ dispatch }) {
      let response = await axios.get(process.env.VUE_APP_BASE_URL + '/padron')
      //console.log(credentials);
      return dispatch('attemppadrones', response.data.payload)
    },
    async attemppadrones({ commit, state }, padron) {
      //console.log('sexo:' + sexo);
      if (padron) {
        commit('SET_PADRON', padron)
      }
      if (!state.padron) {
        return
      }
    },
    async addpadron({ dispatch }, form) {
      let response = await axios.post(process.env.VUE_APP_BASE_URL + '/padron', form)
      //console.log(credentials);
      return dispatch('attemptpadroninsert', response.data)
    },
    async attemptpadroninsert({ commit, state }, data) {
      console.log(data);
      if (data) {
        commit('SET_ADDPADRON', data)

      }
      if (!state.data) {

        return
      }


    },
    async editpadron({ dispatch }, form) {
      let response = await axios.patch(process.env.VUE_APP_BASE_URL + `/padron/${form.IdPadron}`, form)
      //console.log(credentials);
      return dispatch('attemptpadronedit', response.data)
    },
    async attemptpadronedit({ commit, state }, edit) {
      console.log(edit);
      if (edit) {
        commit('SET_EDITPADRON', edit)

      }
      if (!state.edit) {

        return
      }


    },
    async desactivar({ commit }, idvideo) {
      //console.log(idvideo)
      let response = await axios.delete(process.env.VUE_APP_BASE_URL + '/padron/' + idvideo)
      commit('DELETE_PADRON', idvideo)
      console.log(response.data.payload.msg)
      location.reload();

    }
  },
  getters: {
    padronesget(state) {
      return state.padron
    },
  }

})
