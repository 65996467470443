<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <v-list-tile v-bind="attrs" v-on="on">
        <v-list-tile-action>
          <v-icon>fa fa-sign-out</v-icon>
        </v-list-tile-action>
        <v-list-tile-content>
          <v-list-tile-title>logout</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
    </template>
    <v-card>
      <v-card-title class="headline">
        ¿Seguro que desea cerrar sesión?
      </v-card-title>
      <v-card-text
        >Al cerrar la sesión deberá de escribir de nuevo su correo y contraseña
        si desea acceder al sistema</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="dialog = false">
          Cancelar
        </v-btn>
        <v-btn color="green darken-1" text @click.prevent="signOut">
          cerrar sesión
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "logout",
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user"
    })
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    ...mapActions({
      signOutAction: "auth/signOut"
    }),
    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace({
          name: "login"
        });
      });
    }
  }
};
</script>
