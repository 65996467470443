<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="primary" v-bind="attrs" v-on="on">
        <span class="white--text">
          <v-icon right dark>fa fa-user-plus</v-icon>
          Agregar</span
        >
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Agregar Usuario</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn color="white" flat text @click.native="submit">
            Guardar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader></v-subheader>
        <div class="list-item-mod">
          <v-app id="inspire">
            <v-tabs centered color="transparent" dark icons-and-text>
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab href="#tab-1"> Datos Generales </v-tab>

              <v-tab href="#tab-2">Contraseña </v-tab>

              <v-tab href="#tab-3"> Foto de Perfil </v-tab>

              <v-tab-item :value="'tab-1'">
                <v-card flat>
                  <v-card-text>
                    <v-form @submit.prevent="submit">
                      <v-subheader>Nombre</v-subheader>
                      <v-divider></v-divider>
                      <v-container>
                        <v-layout row wrap>
                          <v-flex xs12 sm6 md3>
                            <v-text-field
                              label="Nombre"
                              outline
                              v-model="form.Nombre1"
                              required
                              maxlength="30"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6 md3>
                            <v-text-field
                              label="Segundo nombre"
                              outline
                              maxlength="30"
                              v-model="form.Nombre2"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6 md3>
                            <v-text-field
                              label="Apellido paterno"
                              outline
                              maxlength="30"
                              v-model="form.PrimerApellido"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6 md3>
                            <v-text-field
                              label="Apellido Materno"
                              outline
                              maxlength="30"
                              v-model="form.SegundoApellido"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <v-subheader>Informacion</v-subheader>
                      <hr />
                      <v-container>
                        <v-layout row wrap>
                          <v-flex xs12 sm6 md3>
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="form.FechaNac"
                              persistent
                              lazy
                              full-width
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="form.FechaNac"
                                  label="Fecha de nacimiento"
                                  outline
                                  prepend-icon="fa fa-calendar"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="form.FechaNac" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                  flat
                                  color="primary"
                                  @click="modal = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  flat
                                  color="primary"
                                  @click="$refs.dialog.save(form.FechaNac)"
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-dialog>
                          </v-flex>

                          <v-flex xs12 sm6 md3>
                            <v-select
                              :items="getsexo"
                              label="Género"
                              item-text="TpGenero"
                              outline
                              item-value="IdGenero"
                              v-model="form.IdGenero"
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md3>
                            <v-select
                              :items="tipodoc"
                              label="Tp Identificación"
                              item-text="Doc"
                              item-value="TpIde"
                              outline
                              maxlength="30"
                              v-model="form.TpIde"
                            ></v-select>
                          </v-flex>

                          <v-flex xs12 sm6 md3>
                            <v-text-field
                              label="Cédula/Dimex"
                              outline
                              v-model="form.NoIdentificacion"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <v-container>
                        <v-layout row wrap>
                          <v-flex xs12 sm6 md4>
                            <v-select
                              :items="getprofesion"
                              label="Profesión"
                              item-text="Profesion"
                              item-value="IdProfesion"
                              outline
                              v-model="form.IdProfesion"
                            ></v-select>
                          </v-flex>

                          <v-flex xs12 sm6 md4>
                            <v-select
                              :items="filteredProducts"
                              label="Puesto/Perfil"
                              item-text="TpPadron"
                              item-value="IdPadron"
                              
                              outline
                              v-model="form.IdPadron"
                            ></v-select>
                          </v-flex>

                          <v-flex xs12 sm6 md4>
                            <v-select
                              :items="Estado"
                              label="Estado"
                              item-text="Estado"
                              item-value="IdEstado"
                              outline
                              v-model="form.IdEstado"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <!-- -->
                      <v-subheader>Direccion</v-subheader>
                      <hr />
                      <v-container>
                        <v-layout row wrap>
                          <!--    <v-flex xs12 sm6 md3>
                            <v-select
                              :items="getpostal"
                              label="Provincia"
                              :item-text="
                                (items) =>
                                  `${items.Provincia}-${items.CodigoPostal}`
                              "
                              item-value="CodigoPostal"
                              outline
                              v-model="cantones"
                              @change="change1"
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md3>
                            <v-select
                              :items="getpostal"
                              label="Canton"
                              :item-text="(items) => `${items.Canton}`"
                              item-value="CodigoPostal"
                              outline
                              v-model="cantones"
                              @change="change1"
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md3>
                            <v-select
                              :items="getpostal"
                              label="Distrito"
                              :item-text="(items) => `${items.Distrito}`"
                              item-value="CodigoPostal"
                              outline
                              v-model="cantones"
                              @change="change1"
                            ></v-select>
                          </v-flex
                          <v-flex xs12 sm6 md3>
                            <v-select
                              :items="getpostal"
                              label="Codigo Postal"
                              :item-text="(items) => `${items.CodigoPostal}`"
                              item-value="CodigoPostal"
                              outline
                              v-model="form.CodigoPostal"
                              @change="change1"
                            ></v-select>
                          </v-flex>>-->
                          <v-flex xs12 sm6 md3>
                            <v-textarea
                              outline
                              name="input-7-4"
                              label="Ingrese direccion"
                              placeholder="Ingrese direccion"
                              v-model="form.Direccion"
                              maxlength="200"
                            ></v-textarea>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <!-- -->
                      <v-subheader>Contacto</v-subheader>
                      <hr />
                      <v-container>
                        <v-layout row wrap>
                          <v-flex xs12 sm6 md6>
                            <v-text-field
                              label="Email"
                              outline
                              v-model="form.Email"
                              maxlength="30"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6>
                            <v-text-field
                              type="tel"
                              label="Movil"
                              maxlength="10"
                              outline
                              v-model="form.Movil"
                            ></v-text-field>
                          </v-flex>
                          <!--  <div v-if="seelayout3" id="hide">
                            <v-flex xs12 sm6 d-flex>
                              <v-select
                                :items="getsustituto"
                                label="Suplente de..."
                                :item-text="
                                  (items) =>
                                    `${items.Nombre1} ${items.PrimerApellido}`
                                "
                                outline
                                item-value="IdUsuario"
                                v-model="form.IdTitularAReemplazar"
                              ></v-select>
                            </v-flex>
                          </div>-->
                        </v-layout>
                      </v-container>
                      <!--  <v-subheader>Suplente(s)</v-subheader>

        <hr>
     <v-container>
    <v-layout row wrap>
       <v-flex xs12 sm6>
          <v-select
            v-model="e7"
            :items="states"
            label="Select"
            multiple
            chips
            hint="What are the target regions"
            persistent-hint
          ></v-select>
        </v-flex>
    </v-layout>
    </v-container>     
    
            <v-subheader>Seguridad</v-subheader>
        <hr>
      <v-container>
        <v-layout row wrap>
          <v-flex xs12 sm6 md4>
             <v-text-field
              label="Cve Usuario"
              outline
            ></v-text-field>
          </v-flex>
  
          <v-flex xs12 sm6 md4>
             <v-text-field
              label="Contraseña"
              outline
            ></v-text-field>
          </v-flex>
  
          <v-flex xs12 sm6 md4>
             <v-text-field
              label="Confirmar Contraseña"
              outline
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md4>
          
           <VueLoadingButton
            type="submit"
      aria-label="Post message"
      class="btn btn-lg btn-block btn_login"
      @click.native="submit"
      :loading="isLoading"
      :styled="isStyled"
    >Guardar</VueLoadingButton>
          </v-flex>
        </v-layout>
      </v-container>  -->
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-2'">
                <v-card flat>
                  <v-card-text>
                    <v-subheader>Seguridad</v-subheader>
                    <hr />
                    <v-container>
                      <v-layout row wrap>
                        <v-flex xs12 sm6 md4>
                          <v-text-field
                            label="Contraseña"
                            outline
                            v-model="form.ReContrasena"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md4>
                          <v-text-field
                            label="Confirmar Contraseña"
                            outline
                            v-model="form.Contrasena"
                          ></v-text-field>
                        </v-flex>
                        <br />
                        <v-flex xs12 sm12 md6> </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-3'">
                <v-card flat>
                  <v-card-text>
                    <v-subheader>Seguridad</v-subheader>
                    <hr />
                    <v-container>
                      <v-layout row wrap>
                        <v-flex xs12 sm6 md4>
                          <input
                            type="file"
                            name="file"
                            id="file"
                            ref="file"
                            @change="onFileUpload"
                            accept="image/*"
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-app>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>

  <script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "adduser",
  components: {},
  data() {
    return {
      filteredProducts: [],
      selectedprovincia: null,
      FechaNac: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      FILE: null,
      seelayout3: false,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      Estado: [
        {
          Estado: "Activo",
          IdEstado: "9",
        },
        {
          Estado: "Inactivo",
          IdEstado: "10",
        },
      ],
      tipodoc: [
        {
          Doc: "Cédula",
          TpIde: "9",
        },
        {
          Doc: "Dimex",
          TpIde: "10",
        },
      ],
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      e7: [],
      form: {
        CveUsuario: "333445",
        Nombre1: null,
        Nombre2: null,
        PrimerApellido: null,
        SegundoApellido: null,
        IdGenero: null,
        TpIde: null,
        NoIdentificacion: null,
        IdPadron: null,
        Direccion: null,
        FechaNac: null,
        Contrasena: null,
        ReContrasena: null,
        IdEstado: null,
        Email: null,
        Activo: true,
        CodigoPostal: "10101",
        Movil: null,
        Cedula: "x",
        IdProfesion: null,
        IdTitularAReemplazar: null,
      },
      selectedsubst: [],
    };
  },
  methods: {
    ...mapActions({
      add: "adduser/addusers",
    }),
    onFileUpload(event) {
      this.FILE = event.target.files[0];
      //console.log(this.FILE);
    },
    change1() {
      this.selectedprovincia = this.getpostal.find(
        (i) => i.CodigoPostal === this.form.CodigoPostal
      );
      //console.log(this.getpostal.find((i) => i.CodigoPostal === "10101"));
    },
    submit() {
      //console.log(this.form);
      if (this.form.Contrasena == "") {
        alert("El campo contraseña no puede estar vacio");
      } else {
        if (this.form.ReContrasena != this.form.Contrasena) {
          alert("Las contraseñas no coinciden");
        } else {
          this.add(this.form)
            .then(() => {
              //alert("Usuario Agregado con exito");
              //window.location.reload();
              //console.log(this.adduserget.payload.IdUsuario);

              const formData = new FormData();
              formData.append("FotoPerfil", this.FILE, this.FILE.name);
              axios
                .patch(
                  process.env.VUE_APP_BASE_URL +
                    `/usuarios-update-foto-perfil/${this.adduserget.payload.IdUsuario}`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((resp) => {
                  console.log(resp);
                  //this.imagePath = resp.data.path;
                  alert("Usuario Agregado con exito");
                  window.location.reload();
                })
                .catch(() => {
                  //console.log("fail");
                  alert("Error al crear usuario, intntelo de nuevo mas tarde ");
                });
            })
            .catch(() => {
              //console.log("fail");
              alert("Usuario o contraseña incorrectos.");
            });
        }
      }
      this.errors = [];
      if (!this.form.Email) {
        alert("Email requerido.");
      }
      if (!this.form.Contrasena) {
        alert("Password requerdio.");
      }
    },

    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.selectedsubst = [];
        } else {
          this.selectedsubst = this.getsustituto.slice();
        }
      });
    },
    showHide() {
      if (this.form.IdPadron == 1004 || this.form.IdPadron == 1005) {
        this.seelayout3 = true;
      } else {
        this.seelayout3 = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      getsexo: "adduser/sexoget",
      getprofesion: "adduser/profesionget",
      getpostal: "adduser/postalget",
      getpadron: "adduser/padronget",
      getsustituto: "adduser/sustitutoget",
      adduserget: "adduser/adduserget",
    }),
  },
  mounted() {
    this.$store.dispatch("adduser/fetchsexo");
    this.$store.dispatch("adduser/fetchprofesion");
    this.$store.dispatch("adduser/fetchpostal");
    this.$store.dispatch("adduser/fetchpadron");
    this.$store.dispatch("adduser/fetchsubstitu");
     this.filteredProducts = this.getpadron.filter((product) =>
      product.Activo.includes("S")
    );
  },
};
</script>