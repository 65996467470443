import axios from 'axios'
export default ({
    namespaced: true,
    state: {
        tema: null,
        logo: null

    },
    mutations: {
        SET_TEMA(state, tema) {
            // console.log('ccccc' + tema)
            state.tema = tema
        },
        SET_LOGO(state, logo) {
            // console.log('ccccc' + tema)
            state.logo = logo
        }

    },
    actions: {
        async tema({ dispatch }) {
            let response = await axios.get(process.env.VUE_APP_BASE_URL + '/pgeneral/8')
           // console.log(response.data);
            return dispatch('attempttema', response.data)

        },
        async attempttema({ commit, state }, tema) {
            if (tema) {
                //console.log('tema' + tema);
                commit('SET_TEMA', tema.payload[0].Valor)
                //commit('SET_USER', token)
            }
            if (!state.tema) {

                return
            }


        },

        async logo({ dispatch }) {
            let response = await axios.get(process.env.VUE_APP_BASE_URL + '/pgeneral/7')
           // console.log(response.data);
            return dispatch('attemptlogo', response.data)

        },
        async attemptlogo({ commit, state }, logo) {
            if (logo) {
               // console.log('logo' + logo);
                commit('SET_LOGO', logo.payload[0].Valor)
                //commit('SET_USER', token)
            }
            if (!state.logo) {

                return
            }


        },

    },
    getters: {
        tema(state) {
            console.log('ccccc' + state.tema)
            return state.tema

        },
        logo(state) {
            //console.log('ccccc' + state.tema)
            return state.logo

        }
    }

})
