<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="dateVal"
    persistent
    lazy
    dark
    full-width
    width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :disabled="inactive"
        v-model="dateVal"
        :label="labelpiker"
        readonly
        outline
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="dateVal" scrollable locale="es-es">
      <v-spacer></v-spacer>
      <v-btn flat color="primary" @click="$refs.dialog.save(date)"
        >Cerrar</v-btn
      >
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  props: {
    labelpiker: {
      type: String,
      required: false,
      default() {
        return "Fecha";
      }
    },
    date: {
      type: String,
      required: false
    },
    inactive: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  name: "datepicker",
  mounted: function() {
    this.dateVal = new Date().toISOString().substr(0, 10);
  },
  computed: {
    dateVal: {
      get() {
        return this.date;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  data: () => ({
    menu: false,
    modal: false,
    menu2: false
  })
};
</script>
