// import axios from 'axios'

export default {
    namespaced: true,
    state: {
    },
    mutations: {

    },
    actions: {

    },
    getters: {

    }

}