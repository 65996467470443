import axios from 'axios'
export default {
  namespaced: true,
  state: {
    posts: []

  },

  mutations: {
    SET_ASAMBLEA(state, posts) {
      state.posts = posts
    },
  },
  actions: {
    async fetchasambleas() {
      let response = await axios.get(process.env.VUE_APP_BASE_URL + '/asamblea?Estado=EN CURSO');
      console.log(response.data.payload);
      return response.data.payload.asamblea;
    },
    async attempt({ commit, state }, posts) {
      if (posts) {
        commit('SET_ASAMBLEA', posts)
      }
      if (!state.posts) {

        return
      }


    },
  },
  getters: {
    asambleasget(state) {
      return state.posts
    },
  }

}