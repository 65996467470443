// import axios from 'axios'
import { logIn } from '../../utilities/fetchUtil'

export default ({
  namespaced: true,
  state: {
    token: null,
    usuario: ''
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_USER(state, usuario) {
      //console.log('ccccc' + usuario)
      state.usuario = usuario
    },
  },
  actions: {
    async signIn({
      dispatch
    }, credentials) {
      let response = await logIn(credentials)
      //let response = await axios.post(process.env.VUE_APP_BASE_URL + '/login', credentials)
      //console.log(credentials);
      return dispatch('attempt', response.data.token),
        dispatch('attempt1', response.data.usuario)
    },
    async attempt({
      commit,
      state
    }, token) {
      if (token) {
        commit('SET_TOKEN', token)
        //commit('SET_USER', token)
      }
      if (!state.token) {
        return
      }
    },
    async attempt1({
      commit,
      state
    }, usuario) {
      if (usuario) {
        commit('SET_USER', usuario)
      }
      if (!state.usuario) {
        return
      }
    },
    signOut({
      commit
    }) {
      //return axios.post('http://localhost:3000/logout').then(()=>{
      commit('SET_TOKEN', null)
      commit('SET_USER', null)
     
      localStorage.removeItem('Qses')
      // })
    }
  },
  getters: {
    authenticated(state) {
      return state.token
    },
    user(state) {
      return state.usuario
    },
  }

})