<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-tile v-bind="attrs" v-on="on" @click="setTest()">
        <v-list-tile-title>Editar</v-list-tile-title>
        <v-list-tile-action>
          <v-icon color="blue darken-2">fa fa-pencil</v-icon>
        </v-list-tile-action>
      </v-list-tile>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Editar Encuesta </v-toolbar-title>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader></v-subheader>
        <div class="list-item-mod">
          <v-app id="inspire">
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      disabled
                      label="Cve. Encuesta"
                      outline
                      v-model="patchTest.CveEncuesta"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <datepicker
                      :inactive="true"
                      labelpiker="Fecha Registro"
                      :date="patchTest.FechaRegistro"
                      @input="patchTest.FechaRegistro = $event"
                    ></datepicker>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select
                      :items="asamblea_state(estados)"
                      label="Estado"
                      outline
                      v-model="forGetIdState"
                      @input="getIdEstado"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      disabled
                      label="Registrado Por:"
                      outline
                      :value="user.Nombre1"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container>
                <v-subheader>Descripción</v-subheader>
                <hr />
                <vue-editor v-model="patchTest.Encuesta" />
              </v-container>

              <v-container class="seccion-tabla">
                <v-layout row>
                  <v-flex xs12 sm6 md6 class="mx-auto text-xs-center">
                    <div class="texto-borde">
                      <v-subheader class="mx-auto text-xs-center"
                        >Opciones a considerar:</v-subheader
                      >
                      <table class="formato-tabla mx-auto text-xs-center">
                        <tr v-for="(content, index) in tableRows" :key="index">
                          <td>
                            {{ content }}
                          </td>
                          <td>
                            <v-text-field></v-text-field>
                          </td>
                          <td>
                            <button @click="deleteTableRow(index)">
                              <v-icon>fa fa-trash</v-icon>
                            </button>
                          </td>
                        </tr>
                      </table>

                      <v-btn
                        @click="addTableRow()"
                        color="successs"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <!--  <v-icon right dark>fa fa-plus</v-icon> -->
                        Agregar opción
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>

              <v-container class="d-flex justify-space-between">
                <v-flex xs12 sm6 md3 class="mx-auto text-xs-center">
                  <v-btn color="successs" @click="editTest()"> Grabar </v-btn>
                </v-flex>
              </v-container>
            </v-form>
          </v-app>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>


  <script>
import { mapGetters, mapState } from "vuex";
import datepicker from "@/components/datepicker.vue";
import axios from "axios";

export default {
  name: "editTest",
  props: ["encuesta"],
  components: { datepicker },
  mounted() {},
  data() {
    return {
      tableRows: [],
      counter: 0,
      forGetIdState: null,
      notifications: false,
      dialog: false,
      sound: true,
      widgets: false,
      e7: [],
      patchTest: {
        CveEncuesta: "",
        FechaRegistro: "",
        IdEstado: null,
        Encuesta: "",
        IdUsuario: "",
        Quorum: 0,
      },
    };
  },
  methods: {
    setTest() {
      let encuesta = this.encuesta;
      this.patchTest.CveEncuesta = encuesta.CveEncuesta;
      this.patchTest.FechaRegistro = encuesta.FechaRegistro;
      this.setEstado(encuesta.IdEstado);
      this.patchTest.IdEstado = encuesta.IdEstado;
      this.patchTest.Encuesta = encuesta.Encuesta;
      this.patchTest.IdUsuario = encuesta.IdUsuario;
      this.patchTest.Quorum = encuesta.Quorum;
    },
    async editTest() {
      let encuesta = this.encuesta;
      console.log(this.patchTest);
      await axios
        .patch(
          process.env.VUE_APP_BASE_URL + `/encuestas/${encuesta.IdEncuesta}`,
          this.patchTest
        )
        .then(() => {
          alert("Encuesta actualizada exitosamente");
          this.$store.dispatch("encuestas/fetchencuestas");
          this.dialog = false;
        })
        .catch(() => {
          alert("Error del sistema, por favor contacte a su administrador");
        });
    },
    getIdEstado() {
      for (let i = 0; i < this.estados.length; i++) {
        if (this.estados[i].Estado == this.forGetIdState) {
          this.patchTest.IdEstado = this.estados[i].IdEstado;
        }
      }
    },
    setEstado(id) {
      for (let i = 0; i < this.estados.length; i++) {
        if (this.estados[i].IdEstado == id) {
          this.forGetIdState = this.estados[i].Estado;
        }
      }
    },
    asamblea_state(estados) {
      let getEstados = estados.map((estado) => {
        return estado.Estado;
      });
      return getEstados;
    },
    addTableRow: function () {
      this.counter++;
      this.tableRows.push("#" + this.counter + ":");
    },
    deleteTableRow: function (idx) {
      this.counter--;
      this.tableRows.splice(idx, 1);
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    ...mapState("encuestas", ["estados"]),
  },
};
</script >;



<style scoped>
.titulo-bg {
  background-color: #2196f3;
  color: white;
}
.texto-borde {
  border-width: 5px;
  border-style: solid;
  border-color: #32b294;
  border-radius: 20px;
  padding: 5px;
}

.seccion-tabla {
  width: 70%;
  align-content: center;
}
</style>
