import { mapState } from "vuex";
import jsPDF from "jspdf";
import addmocion from "@/components/addmocion.vue";
import editmocion from "@/components/editmocion.vue";

import axios from "axios";

export default {
  name: "Mociones",
  components: {
    addmocion,
    editmocion
  },
  mounted() {
    this.$store.dispatch("mociones/fetchestados");
    this.$store.dispatch("mociones/fetchmociones");
  },
  data() {
    return {
      output: null,
      radioGroup: 1,
      search: "",
      pagination: {},
      admin: [],
      presidente: [],
      principal: [],
      voto: [],
      voz: [],
      headers: [
        { text: "", value: "edit", sortable: false, class: "primary" },
        {
          text: "Mocion",
          align: "left",
          sortable: false,
          value: "mocion",
          class: "primary"
        },
        { text: "Descripcion", value: "", sortable: false, class: "primary" },
        {
          text: "Registrado por",
          value: "",
          sortable: false,
          class: "primary"
        },
        {
          text: "Fecha Registro",
          value: "",
          sortable: false,
          class: "primary"
        },
        {
          text: "Estado",
          value: "presidente",
          sortable: false,
          class: "primary"
        }
      ]
    };
  },
  methods: {
    async actionMotion(idMotion, action) {
      if (action == "delete") {
        // let responseDeleteMotion =
        await axios
          .delete(process.env.VUE_APP_BASE_URL + `/mocion/` + idMotion)
          .then(() => {
            alert("Mocion eliminada");
            this.$store.dispatch("mociones/fetchmociones");
          })
          .catch(() => {
            alert("Ups! Algo anda mal");
          });
      }
      if (action == "edit") {
        this.$store.state.mociones.idEdMo = idMotion;
        console.log(this.$store.state.mociones.idEdMo);
        this.$store.state.mociones.dialog = true;
      }
    },
    createPDF() {
      let reformatedArryMotions = this.mociones.map(mocion => {
        let motion = {
          mocion: mocion.CveMocion,
          register_by: mocion.Ct_Usuario.Nombre1,
          date_register: mocion.FechaRegistro,
          description: mocion.Mocion,
          state: mocion.Ct_Estado.Estado
        };
        return motion;
      });
      console.log(reformatedArryMotions);
      var headers = [
        {
          id: "mocion",
          name: "mocion",
          prompt: "Mocion",
          width: 50,
          color: "#fff",
          align: "center",
          padding: 5
        },
        {
          id: "description",
          name: "description",
          prompt: "Descripción",
          width: 65,
          align: "center",
          padding: 5
        },
        {
          id: "register_by",
          name: "register_by",
          prompt: "Registrado Por",
          width: 65,
          align: "center",
          padding: 5
        },
        {
          id: "date_register",
          name: "date_register",
          prompt: "Fecha Registro",
          width: 65,
          align: "center",
          padding: 5
        },
        {
          id: "state",
          name: "state",
          prompt: "Estado",
          width: 45,
          align: "center",
          padding: 5
        }
      ];

      var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "l" });
      doc.text("Mociones", 125, 40);
      doc.table(25, 50, reformatedArryMotions, headers, {
        headerBackgroundColor: "#8DAF0B",
        autoSize: false,
        format: "a6",
        text: "#fff"
      });
      doc.save("mociones.pdf");
    }
  },
  computed: {
    VoiceDisabled() {},
    VoteDisabled() {},
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    ...mapState("mociones", ["mociones"])
  }
};
