<template>
  <v-layout>
    <v-btn
      color="red lighten-2"
      @click="
        () => {
          this.$refs.vac1.startCountdown(true);
        }
      "
      @click.stop="dialog = true"
      dark
    >
      modal tipo 2
    </v-btn>

    <v-dialog v-model="dialog" persistent max-width="500">
      <v-container titulo-bg grid-list-xs fill-height>
        <v-layout row wrap align-center>
          <v-flex>
            <h3 class="text-xs-center">Encuesta</h3>
          </v-flex>
        </v-layout>
      </v-container>

      <!-- <v-card-title class="headline">Alerta de Mocion</v-card-title> -->

      <v-layout row>
        <v-card flat class="mx-auto text-xs-center">
          <v-img
            :src="alerta"
            aspect-ratio="2"
            width="65"
            height="65"
            style="float: left"
          >
          </v-img>
          <!--  <v-card flat><h1>Mocion</h1></v-card> -->
        </v-card>
      </v-layout>
      <v-layout row>
        <v-card flat class="mx-auto text-xs-center">
          <vac
            @finish="dialog = false"
            :left-time="6000"
            :auto-start="false"
            ref="vac1"
          >
            <template v-slot:process="{ timeObj }" :activator="{ on, attrs }">
              <v-layout row wrap>
                <v-flex>
                  <v-card flat class="mx-auto text-xs-center">
                    <v-card-text class="mx-auto text-xs-center">
                      <h3>Tiempo restante:</h3>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 sm6 md6 offset-sm3>
                  <v-card dark color="blue" class="rounded-card">
                    <v-card-text>
                      {{ `${timeObj.m}:${timeObj.s}` }}</v-card-text
                    >
                    <audio autoplay>
                      <source src="@/assets/beep.mp3" type="audio/wav" />
                    </audio>
                  </v-card>
                </v-flex>
              </v-layout>
            </template>
          </vac>
        </v-card>
      </v-layout>

      <v-layout row>
        <v-card-actions class="mx-auto text-xs-center">
          <v-spacer></v-spacer>
          <v-btn color="successs" text @click="dialog = false">Aceptar</v-btn>
        </v-card-actions>
      </v-layout>
    </v-dialog>
  </v-layout>
</template>





<script>
export default {
  data() {
    return {
      dialog: false,
      alerta: require("@/assets/alerta2.gif")
    };
  }
};
</script>
<style scoped>
.titulo-bg {
  background-color: #2196f3;
  color: white;
}
.texto-borde {
  border-width: 5px;
  border-style: solid;
  border-color: #32b294;
  border-radius: 20px;
  padding: 5px;
}
</style>