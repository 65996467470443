import { mapState } from "vuex";
import jsPDF from "jspdf";
import addencuesta from "@/components/addencuesta.vue";
import eliminarEncuesta from "@/components/eliminarEncuesta.vue";
import editencuesta from "@/components/editencuesta.vue";

import axios from "axios";

export default {
  name: "Encuestas",
  components: {
    addencuesta,
    eliminarEncuesta,
    editencuesta
  },
  mounted() {
    this.$store.dispatch("encuestas/fetchestados");
    this.$store.dispatch("encuestas/fetchencuestas");
  },
  data() {
    return {
      dialog: false,

      output: null,
      radioGroup: 1,
      search: "",
      pagination: {},
      selected: [],
      admin: [],
      presidente: [],
      principal: [],
      voto: [],
      voz: [],
      headers: [
        { text: "", value: "edit", sortable: false, class:"primary", },
        {
          text: "Cve. Encuesta",
          align: "center",
          value: "encuesta", class:"primary",
        },
        {
          text: "Descripción",
          align: "center",
          value: "Encuesta",
          sortable: false, class:"primary",
        },
        {
          text: "Registrado por",
          align: "center",
          value: "Ct_Usuario.Nombre1",
          sortable: false, class:"primary",
        },
        {
          text: "Fecha Registro",
          align: "center",
          value: "FechaRegistro",
          sortable: false, class:"primary",
        },
        {
          text: "Estado",
          value: "Ct_Estado.Estado",
          align: "center",
          sortable: false, class:"primary",
        }
      ],
      forpdf: []
    };
  },
  methods: {
    async actionTest(idTest, action) {
      if (action == "delete") {
        let responseDeleteTest = await axios

          .delete(process.env.VUE_APP_BASE_URL + `/encuestas/` + idTest)
          .then(() => {
            alert("Encuesta eliminada");
            this.$store.dispatch("encuestas/fetchencuestas");
          })
          .catch(() => {
            alert("Error del sistema, por favor contacte a su administrador");
          });
        responseDeleteTest = responseDeleteTest.data.payload;
        console.log(responseDeleteTest);
      }
      if (action == "edit") {
        console.log("edit" + idTest);
      }
    },
    createPDF() {
      let reformatedArryTests = this.encuestas.map(encuesta => {
        let test = {
          encuesta: encuesta.CveEncuesta,
          register_by: encuesta.Ct_Usuario.Nombre1,
          date_register: encuesta.FechaRegistro,
          description: encuesta.Encuesta,
          state: encuesta.Ct_Estado.Estado
        };
        return test;
      });
      console.log(reformatedArryTests);
      var headers = [
        {
          id: "encuesta",
          name: "encuesta",
          prompt: "Encuesta",
          width: 50,
          color: "#fff",
          align: "center"
        },
        {
          id: "description",
          name: "description",
          prompt: "Descripción",
          width: 103,
          align: "left",
          padding: 5
        },
        {
          id: "register_by",
          name: "register_by",
          prompt: "Registrado Por",
          width: 65,
          align: "center",
          padding: 5
        },
        {
          id: "date_register",
          name: "date_register",
          prompt: "Fecha Registro",
          width: 65,
          align: "center",
          padding: 5
        },
        {
          id: "state",
          name: "state",
          prompt: "Estado",
          width: 45,
          align: "center",
          padding: 5
        }
      ];

      var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "l" });
      doc.text("Encuestas", 125, 18);
      doc.table(25, 25, reformatedArryTests, headers, {
        autoSize: false,
        format: "a4",
        text: "#fff",
        align: "center"
      });
      doc.save("Encuestas.pdf");
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    ...mapState("encuestas", ["encuestas"])
  }
};
