export default {
  namespaced: true,
  state: {
    desserts: [
      {
        eleccion: '0920-19-001',
        id: 1,
        description: "Revision de estados Financieros",
        register_by: "Orlando Cabrera",
        date_register: "08/12/2012",
        state: 'Previo',

      },
      {
        eleccion: '0920-19-001',
        id: 2,
        description: "Revision de estados Financieros",
        register_by: "Orlando Cabrera",
        date_register: "08/12/2012",
        state: 'En curso',


      },
      {
        eleccion: '0920-19-001',
        id: 3,
        description: "Revision de estados Financieros",
        register_by: "Orlando Cabrera",
        date_register: "08/12/2012",
        state: 'Cancelada',

      },
      {
        eleccion: '0920-19-001',
        id: 4,
        description: "Revision de estados Financieros",
        register_by: "Orlando Cabrera",
        date_register: "08/12/2012",
        state: 'Inactiva',


      },
      {
        eleccion: '0920-19-001',
        id: 5,
        description: "Revision de estados Financieros",
        register_by: "Orlando Cabrera",
        date_register: "08/12/2012",
        state: 'Concluida',


      },
      {
        eleccion: '0920-19-001',
        id: 6,
        description: "Revision de estados Financieros",
        register_by: "Orlando Cabrera",
        date_register: "08/12/2012",
        state: 'En curso',


      },
      {
        eleccion: '0920-19-001',
        id: 7,
        description: "Revision de estados Financieros",
        register_by: "Orlando Cabrera",
        date_register: "08/12/2012",
        state: 'En curso',


      },
      {
        eleccion: '0920-19-001',
        id: 8,
        description: "Revision de estados Financieros",
        register_by: "Orlando Cabrera",
        date_register: "08/12/2012",
        state: 'Concluida',


      },
      {
        eleccion: '0920-19-001',
        id: 9,
        description: "Revision de estados Financieros",
        register_by: "Orlando Cabrera",
        date_register: "08/12/2012",
        state: 'En curso',


      },
      {
        eleccion: '0920-19-001',
        id: 10,
        description: "Revision de estados Financieros",
        register_by: "Orlando Cabrera",
        date_register: "08/12/2012",
        state: 'En curso',


      }
    ],

  },

}
