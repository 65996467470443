<template>
  <v-snackbar multi-line v-model="snack.show" :timeout="snack.timeout">
    {{ snack.text }}
    <v-btn :color="snack.btn_color" text @click="snack.show = false">
      {{ snack.btn_text }}
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "snackbar",
  props: {
    snack: {
      type: Object,
      defaul: () => ({
        show: false,
        timeout: 2000,
        btn_color: "pink",
        btn_text: "test",
        text: "¡Éxito!",
      }),
    },
  },
  data: () => ({}),
  created: function () {
    console.log(`Start: ${this.$options.name}`);
  },
  methods: {},
  watch: {
    snack() {
      console.log(...this.snack);
    },
  },
  computed: {
    snackbar() {
      return this.snack;
    },
  },
};
</script>

