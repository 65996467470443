<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-card-text v-bind="attrs" v-on="on">
        <v-img :src="image5" aspect-ratio="2" class="rounded-card">
          <v-container>
            <span class="white--text font-weight-bold">Conferencias</span>
            <br /><br />
          </v-container>
        </v-img>
      </v-card-text>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Conferencia </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader></v-subheader>
        <div class="list-item-mod">
          <v-app id="inspire">
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      disabled
                      label="Zoom"
                      outline
                      v-model="signature"
                      @click="openZoom"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container class="d-flex justify-space-between">
                <v-flex xs12 sm6 md3 class="mx-auto text-xs-center">
                  <v-btn color="successs" @click="launch()"> Entrar </v-btn>
                </v-flex>
                <v-flex xs12 sm12 md12 class="mx-auto text-xs-center">
                  <iframe
                    title="Asamblea"
                    :src="zoom"
                    style="width: 600px; height: 600px"
                  ></iframe>
                </v-flex>
              </v-container>
            </v-form>
          </v-app>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>

  <script>
import { mapGetters, mapState } from "vuex";
import { zoomSignature, zoomTest } from "../utilities/fetchUtil";

export default {
  name: "conferencia",
  components: {},
  mounted() {
    zoomTest().then((res) => {
      console.log(res.status);
    });
    this.sign();
  },
  data() {
    return {
      dialog: false,
      image5: require("@/assets/b5.jpg"),
      signature: "",
      zoom: "",
    };
  },
  methods: {
    sign() {
      let zoomData = {
        meetingNumber: process.env.VUE_APP_ZOOM_MEET,
        role: process.env.VUE_APP_ZOOM_ATTENDEE,
      };
      // Firma de la conferencia
      zoomSignature(zoomData).then((response) => {
        this.signature = response.data.payload.signature;
        console.log(`ZOOM: ${this.signature}`);
      });
    },
    openZoom() {
      if (this.signature.includes("zoom")) {
        window.open(this.signature, "_blank");
      }
    },
    launch() {
      let zoomURL = process.env.VUE_APP_ZOOM_URL.replace(
        ":meet:",
        process.env.VUE_APP_ZOOM_MEET
      ).replace(":password:", process.env.VUE_APP_ZOOM_PASS);
      this.signature = zoomURL;
      // window.open(zoomURL, "_blank");
      // window.open(this.zoom, "_blank");
      this.zoom = process.env.VUE_APP_ZOOM_INSTANCE;
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    ...mapState("preguntas", ["estados"]),
  },
};
</script>

