
import axios from 'axios'

export default ({
  namespaced: true,
  state: {
    token: null,
    user: [],
    sexo: [],
    profesion: [],
    postal: [],
    padron: [],
    sustituto: [],
    data: []

  },
  mutations: {
    SET_ADDUSER(state, data) {
      state.data = data
    },

    SET_SEXO(state, sexo) {
      state.sexo = sexo
    },
    SET_PROF(state, profesion) {
      state.profesion = profesion
    },
    SET_POSTAL(state, postal) {
      state.postal = postal
    },
    SET_PADRON(state, padron) {
      state.padron = padron
    },
    SET_USER(state, user) {
      state.user = user
    },
    SET_SUSTITUTO(state, sustituto) {
      state.sustituto = sustituto
    },

  },
  actions: {
    async addusers({ dispatch }, form) {
      let response = await axios.post(process.env.VUE_APP_BASE_URL + '/signup', form)
      //console.log(credentials);
      return dispatch('attempt', response.data)
    },
    async attempt({ commit, state }, data) {
      //console.log(data);
      if (data) {
        commit('SET_ADDUSER', data)

      }
      if (!state.data) {

        return
      }


    },
    async edituser({ commit }, form) {
      console.log(form)
      let response = await axios.patch(process.env.VUE_APP_BASE_URL + `/usuarios/${form.IdUsuario}`, form)
      commit('EDIT_USER', form)
      console.log(response.data)
    },
    async fechuser({ dispatch }, id) {
      let response = await axios.get(process.env.VUE_APP_BASE_URL + '/signup' + id,)
      //console.log(credentials);
      return dispatch('attemptuser', response.data.payload)
    },
    async attemptuser({ commit, state }, user) {
      //console.log(data);
      if (user) {
        commit('SET_USER', user)

      }
      if (!state.user) {

        return
      }


    },
    async fetchsexo({ dispatch }) {
      let response = await axios.get(process.env.VUE_APP_BASE_URL + '/genero')
      //console.log(credentials);
      return dispatch('attemptsex', response.data.payload)
    },
    async attemptsex({ commit, state }, sexo) {
      //console.log('sexo:' + sexo);
      if (sexo) {
        commit('SET_SEXO', sexo)
      }
      if (!state.sexo) {

        return
      }


    },
    async fetchprofesion({ dispatch }) {
      let response = await axios.get(process.env.VUE_APP_BASE_URL + '/profesion')
      //console.log(credentials);
      return dispatch('attemptprof', response.data.payload)
    },
    async attemptprof({ commit, state }, profesion) {
      //console.log('sexo:' + profesion);
      if (profesion) {
        commit('SET_PROF', profesion)
      }
      if (!state.profesion) {

        return
      }


    },
    async fetchpostal({ dispatch }) {
      let response = await axios.get(process.env.VUE_APP_BASE_URL + '/codigo-postal')
      //console.log(credentials);
      return dispatch('attemptpstal', response.data.payload)
    },
    async attemptpstal({ commit, state }, postal) {
      //console.log('sexo:' + postal);
      if (postal) {
        commit('SET_POSTAL', postal)
      }
      if (!state.postal) {

        return
      }


    },
    async fetchpadron({ dispatch }) {
      let response = await axios.get(process.env.VUE_APP_BASE_URL + '/padron')
      //console.log(credentials);
      return dispatch('attemptpadron', response.data.payload)
    },
    async attemptpadron({ commit, state }, padron) {
      //console.log('sexo:' + padron);
      if (padron) {
        commit('SET_PADRON', padron)
      }
      if (!state.padron) {

        return
      }


    },
    async fetchsubstitu({ dispatch }) {
      let response = await axios.get(process.env.VUE_APP_BASE_URL + '/usuarios?padron=1003')
      //console.log(credentials);
      return dispatch('attemptstitu', response.data.payload)
    },
    async attemptstitu({ commit, state }, sustituto) {
      //console.log('sexo:' + padron);
      if (sustituto) {
        commit('SET_SUSTITUTO', sustituto)
      }
      if (!state.sustituto) {

        return
      }


    },
    async editpass({ commit }, form) {
      console.log(form)
      let response = await axios.post(process.env.VUE_APP_BASE_URL + `/restart-password`, form)
      commit('EDIT_USER', form)
      console.log(response.data)
    },
  },
  getters: {
    sexoget(state) {
      return state.sexo
    },
    profesionget(state) {
      return state.profesion
    },
    postalget(state) {
      return state.postal
    },
    padronget(state) {
      return state.padron
    },
    sustitutoget(state) {
      return state.sustituto
    },
    adduserget(state) {
      return state.data
    },
  }

})
