import { getInstance, postInstance, patchInstance, deleteInstance } from "./axioConfig";
import { API } from "./constants";

// EMAIL APIs
export const emailTest = () => { return getInstance(API.email) }
export const sendEmail = (params) => { return postInstance(API.email, params) }

// ZOOM APIs
export const zoomSignature = (params) => { return postInstance(API.zoom, params); }
export const zoomTest = () => { return getInstance(API.zoom); }

// AUTH
export const singUp = (params) => { return postInstance(API.signup, params); }
export const logIn = (params) => { return postInstance(API.login, params); }
export const changePassword = (params) => { return postInstance(API.changePassword, params); }
export const restartPassword = (params) => { return postInstance(API.restartPassword, params); }

// USERS
export const createUser = (id, params) => { return postInstance(`${API.users}/${id}`, params); }
export const getUsers = () => { return getInstance(API.users); }
export const getUser = (id) => { return getInstance(`${API.users}/${id}`); }
export const updateUser = (id, params) => { return patchInstance(`${API.users}/${id}`, params); }
export const deleteUser = (id) => { return deleteInstance(`${API.users}/${id}`); }
export const updateUserPhoto = (id, params) => { return patchInstance(`${API.userPhoto}/${id}`, params); }

// GET ASAMBLEA

export const getAsamblea = () => { return getInstance(API.asamblea); }