import axios from "axios";

const options = {
    withCredentials: false,
    headers: { "Content-Type": "application/json" }
}

// Methods creation
const getAxios = axios.create({ options, method: "GET" });
const postAxios = axios.create({ options, method: "POST" });
const putAxios = axios.create({ options, method: "PUT" });
const patchAxios = axios.create({ options, method: "PATCH" });
const deleteAxios = axios.create({ options, method: "DELETE" });

// GET INSTANCE
export const getInstance = (url) => getAxios.get(url).then(res => {
    if (res === null) { return Promise.reject("rejected from post axios"); }
    else { return res.data; }
});
// POST INSTANCE
export const postInstance = (url, params) => postAxios.post(url, params).then(res => {
    if (res === null) { return Promise.reject("rejected from post axios"); }
    else { return res; }
});
// PUT INSTANCE
export const putInstance = (url, params) => putAxios.put(url, params).then(res => {
    if (res === null) { return Promise.reject("rejected from post axios"); }
    else { return res; }
});
// PATH INSTANCE
export const patchInstance = (url, params) => patchAxios.patch(url, params).then(res => {
    if (res === null) { return Promise.reject("rejected from post axios"); }
    else { return res; }
});
// DELETE INSTANCE
export const deleteInstance = (url) => deleteAxios.delete(url).then(res => {
    if (res === null) { return Promise.reject("rejected from delete axios"); }
    else { return res; }
});
