import Vue from 'vue'
import VueRouter from 'vue-router'
import padron from '../views/padron/padron.vue'
import asambleas from '../views/asambleas/asambleas.vue'
import voting_result from '../views/asambleas/voting_result.vue'
import mociones from '../views/mociones/mociones.vue'
import encuestas from '../views/encuestas/encuestas.vue'
import elecciones from '../views/elecciones/elecciones.vue'
import gestionasamblea from '../views/asambleas/gestionasamblea.vue'
import gestionasambleaescritorio from "../views/asambleas/gestionasambleaescritorio.vue"
import usuarios from '../views/usuarios/usuarios.vue'
import home from '../views/home/home.vue'
import perfil from '../views/perfil/perfil.vue'
import settings from '../views/settings/settings.vue'
import preguntas from '../views/preguntas/preguntas.vue'
import recoverpass from '../views/recoverpass/recoverpass.vue'
import test from '../views/test/test.vue'
import store from '../store/index.js'
//import login from '../views/login/login'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/login/login.vue"),
    beforeEnter: (to, from, next) => {
     // console.log(store.getters);
      if (store.getters["auth/authenticated"]) {
        return next({
          name: "home"
        });
      }
      next();
    }
  },
  {
    path: "/padron",
    name: "padron",
    component: padron
  },
  {

    path: '/resultado-votacion',
    name: 'voting_result',
    component: voting_result
  },
  {
    path: '/mociones',
    name: 'mociones',
    component: mociones
  },
  {
    path: '/encuestas',
    name: 'encuestas',
    component: encuestas
  },
  {
    path: '/elecciones',
    name: 'elecciones',
    component: elecciones
  },


  {
    path: '/asambleas',
    name: 'asambleas',
    component: asambleas
  },
  {
    path: "/usuarios",
    name: "usuarios",
    component: usuarios
  },
  {
    path: "/test",
    name: "test",
    component: test
  },
  {
    path: "/preguntas",
    name: "preguntas",
    component: preguntas
  },
  {
    path: "/recoverpass",
    name: "recoverpass",
    component: recoverpass
  },
  {
    path: "/settings",
    name: "settings",
    component: settings
  },
  {
    path: "/perfil",
    name: "perfil",
    component: perfil,
    beforeEnter: (to, from, next) => {
      //console.log(store.getters);
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/home",
    name: "home",
    component: home,
    beforeEnter: (to, from, next) => {
      //console.log(store.getters);
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  },
  {
    path: "/gestion-asamblea",
    name: "gestion-asamblea",
    component: gestionasamblea,

  },

  {
    path: "/gestion-asamblea-escritorio",
    name: "gestion-asamblea-escritorio",
    component: gestionasambleaescritorio,
    beforeEnter: (to, from, next) => {
     //cl console.log(store.getters);
      if (!store.getters["auth/authenticated"]) {
        return next({
          name: "login"
        });
      }
      next();
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});
