import { mapState } from "vuex";
import jsPDF from "jspdf";
import addpregunta from "@/components/addpregunta.vue";
import eliminarPregunta from "@/components/eliminarPregunta.vue";
import editpregunta from "@/components/editpregunta.vue";

import axios from "axios";

export default {
  name: "Preguntas",
  components: {
    addpregunta,
    eliminarPregunta,
    editpregunta
  },
  mounted() {
    this.$store.dispatch("preguntas/fetchestados");
    this.$store.dispatch("preguntas/fetchpreguntas");
  },
  data() {
    return {
      dialog: false,

      output: null,
      radioGroup: 1,
      search: "",
      pagination: {},
      selected: [],
      admin: [],
      presidente: [],
      principal: [],
      voto: [],
      voz: [],
      headers: [
        { text: "", value: "edit", sortable: false, class: "primary" },
        {
          text: "Cve. Pregunta",
          align: "center",

          value: "CvePregunta"
          , class: "primary"
        },
        {
          text: "Descripción",
          align: "center",
          value: "Pregunta",
          sortable: false,
          class: "primary"
        },
        {
          text: "Registrado por",
          align: "center",
          value: "Ct_Usuario.Nombre1",
          sortable: false,
          class: "primary"
        },
        {
          text: "Fecha Registro",
          align: "center",
          value: "FechaRegistro",
          sortable: false,
          class: "primary"
        },
        {
          text: "Estado",
          value: "Ct_Estado.Estado",
          align: "center",
          sortable: false,
          class: "primary"
        }
      ],
      forpdf: []
    };
  },
  methods: {
    async actionTest(idTest, action) {
      if (action == "delete") {
        let responseDeleteTest = await axios

          .delete(process.env.VUE_APP_BASE_URL + `/preguntas/` + idTest)
          .then(() => {
            alert("Pregunta eliminada");
            this.$store.dispatch("preguntas/fetchpreguntas");
          })
          .catch(() => {
            alert("Error del sistema, por favor contacte a su administrador");
          });
        responseDeleteTest = responseDeleteTest.data.payload;
        console.log(responseDeleteTest);
      }
      if (action == "edit") {
        console.log("edit" + idTest);
      }
    },
    createPDF() {
      let reformatedArryTests = this.preguntas.map(pregunta => {
        let test = {
          pregunta: pregunta.CvePregunta,
          register_by: pregunta.Ct_Usuario.Nombre1,
          date_register: pregunta.FechaRegistro,
          description: pregunta.Pregunta,
          state: pregunta.Ct_Estado.Estado
        };
        return test;
      });
      console.log(reformatedArryTests);
      var headers = [
        {
          id: "pregunta",
          name: "pregunta",
          prompt: "Pregunta",
          width: 50,
          color: "#fff",
          align: "center"
        },
        {
          id: "description",
          name: "description",
          prompt: "Descripción",
          width: 103,
          align: "left",
          padding: 5
        },
        {
          id: "register_by",
          name: "register_by",
          prompt: "Registrado Por",
          width: 65,
          align: "center",
          padding: 5
        },
        {
          id: "date_register",
          name: "date_register",
          prompt: "Fecha Registro",
          width: 65,
          align: "center",
          padding: 5
        },
        {
          id: "state",
          name: "state",
          prompt: "Estado",
          width: 45,
          align: "center",
          padding: 5
        }
      ];

      var doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "l" });
      doc.text("Preguntas", 125, 18);
      doc.table(25, 25, reformatedArryTests, headers, {
        autoSize: false,
        format: "a4",
        text: "#fff",
        align: "center"
      });
      doc.save("Preguntas.pdf");
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    ...mapState("preguntas", ["preguntas"])
  }
};
