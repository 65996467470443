<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-card-text v-bind="attrs" v-on="on">
        <v-img :src="image4" aspect-ratio="2" class="rounded-card">
          <v-container>
            <span class="white--text font-weight-bold">Pregunta</span>
            <span class="white--text font-weight-bold">/Nominación</span>
          </v-container>
        </v-img>
      </v-card-text>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Crear Pregunta </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader></v-subheader>
        <div class="list-item-mod">
          <v-app id="inspire">
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      disabled
                      label="Cve. Pregunta"
                      outline
                      v-model="newTest.CvePregunta"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md3>
                    <datepicker
                      :inactive="true"
                      labelpiker="Fecha Registro"
                      :date="newTest.FechaRegistro"
                      @input="newTest.FechaRegistro = $event"
                    ></datepicker>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <!--  <v-select
                      :items="asamblea_state(estados)"
                      label="Estado"
                      outline
                    >
                    </v-select> -->
                    <v-text-field
                      disabled
                      label="Estado:"
                      outline
                      value="EN CURSO"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      disabled
                      label="Registrado Por:"
                      outline
                      :value="user.Nombre1"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container>
                <v-flex xs12 sm6 md3>
                  <v-text-field
                    disabled
                    label="Atendido Por:"
                    outline
                  ></v-text-field>
                </v-flex>
                <v-subheader>Pregunta</v-subheader>
                <hr />
                <vue-editor v-model="newTest.Pregunta" />
              </v-container>

              <v-container class="d-flex justify-space-between">
                <v-flex xs12 sm6 md3 class="mx-auto text-xs-center">
                  <v-btn color="successs" @click="saveTest(user.IdUsuario)">
                    Enviar
                  </v-btn>
                </v-flex>
              </v-container>
            </v-form>
          </v-app>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>

  <script>
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import datepicker from "@/components/datepicker.vue";
export default {
  name: "addpregunta",
  components: {
    datepicker,
  },
  mounted() {
    this.createCvePregunta();
  },
  data() {
    return {
      image4: require("@/assets/b4.jpg"),
      forGetIdState: null,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      e7: [],
      tableRows: [],
      counter: 0,
      newTest: {
        CvePregunta: "",
        FechaRegistro: "",
        IdEstado: 24,
        Pregunta: "",
        IdUsuario: "",
        IdAsamblea: 74,
        IdUsuario_Lectura: "",
      },
    };
  },
  methods: {
    async createCvePregunta() {
      let responseConsecutivo = await axios.get(
        process.env.VUE_APP_BASE_URL + `/pgeneral/3`
      );
      responseConsecutivo = responseConsecutivo.data.payload;

      this.consecutivo = parseInt(responseConsecutivo[0].Valor) + 1;

      var consecutivo;
      if (responseConsecutivo[0].Valor.length == 1) {
        consecutivo = "000" + this.consecutivo;
      }
      if (responseConsecutivo[0].Valor.length == 2) {
        consecutivo = "00" + this.consecutivo;
      }
      if (responseConsecutivo[0].Valor.length == 3) {
        consecutivo = "0" + this.consecutivo;
      }
      if (responseConsecutivo[0].Valor.length == 4) {
        consecutivo = this.consecutivo;
      }
      var dt = new Date();
      var actualMonth = dt.getMonth() + 1;
      this.newTest.CvePregunta =
        "PR-" + dt.getFullYear() + "-" + actualMonth + "-" + consecutivo;
    },
    getIdEstado(estados) {
      console.log(this.forGetIdState);
      let stateGeted;
      for (let i = 0; i < estados.length; i++) {
        if (estados[i].Estado == this.forGetIdState) {
          console.log(estados[i].IdEstado);
          stateGeted == estados[i].IdEstado;
        }
      }
      console.log(stateGeted);
    },
    asamblea_state(estados) {
      let getEstados = estados.map((estado) => {
        return estado.Estado;
      });
      return getEstados;
    },
    async saveTest(iduser) {
      let upConsecutivo = {
        Valor: this.consecutivo.toString(),
      };
      await axios.patch(
        process.env.VUE_APP_BASE_URL + `/pgeneral/3`,
        upConsecutivo
      );
      this.newTest.IdUsuario = iduser;
      this.newTest.IdUsuario_Lectura = iduser;

      /* var estadoId = "13"; */
      /*      this.newMotion.IdEstado = estadoId; */

      /* alert(
        " mensaje" +
          this.newTest.IdEstado +
          "*" +
          this.newTest.CvePregunta +
          "*" +
          this.newTest.FechaRegistro +
          "*" +
          this.newTest.IdUsuario +
          "*" +
          this.newTest.Pregunta +
          "*" +
          this.newTest.IdAsamblea
      ); */

      await axios
        .post(process.env.VUE_APP_BASE_URL + `/preguntas`, this.newTest)
        .then(() => {
          alert("Pregunta Agregada con exito");

          this.newTest.CvePregunta = "";
          this.newTest.Pregunta = "";
          this.createCvePregunta();

          this.dialog = false;
          this.$store.dispatch("preguntas/fetchpreguntas");
        })
        .catch((err) => {
          alert("Error del sistema, por favor contacte a su administrador");
          console.log(err);
        });
    },
    addTableRow: function () {
      this.counter++;
      this.tableRows.push("#" + this.counter + ":");
    },
    deleteTableRow: function (idx) {
      this.counter--;
      this.tableRows.splice(idx, 1);
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    ...mapState("preguntas", ["estados"]),
  },
};
</script>

