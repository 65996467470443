import io from 'socket.io-client';
import {
  Carousel,
  Slide
} from 'vue-carousel';
import {
  VueEditor
} from "vue2-editor";
import {
  mapGetters
} from 'vuex'
import moment from 'moment'
export default {
  name: 'Home',
  data() {
    const socket = io.connect(`${process.env.VUE_APP_BASE_URL_SOCKET}`, { path: '/' });
    return {
      drawer1: null,
      socket,
      dialog: [],
      isFavorite: false,
      state: "started",
      startTime: Date.now(),
      currentTime: Date.now(),
      interval: null,
      horainicio: null,
      ftechhorainicio: null,
      ftechhorafin: null,
      filteredProducts: [],
      filteredProductsasign: [],
      votoselected: false,
      mocionvitacion: {
        IdMocion: null,
        TiempoVotacion: null,
        TpVoto: false,
        IdEstado: "5",
        HoraInicio: null,
       
      },
      output: null,
     
      content: "<h1>texto</h1>",
      valueDeterminate: 50,
      selected: [],
      headers: [{
        text: "Nombre",
        align: "left",
        sortable: true,
        value: "name",
        class: "primary"
      },
      {
        text: "Rol",
        align: "left",
        sortable: false,
        class: "primary"
      },
      {
        text: "Comentarios",
        align: "left",
        sortable: false,
        class: "primary"
      }
      ],
      item2s: [],
      headers2: [{
        text: "",
        align: "left",
        sortable: false,
        value: "name",
        class: "primary"
      },
      {
        text: "Mocion",
        align: "left",
        sortable: false,
        value: "Mocion",
        class: "primary"
      },
      {
        text: "Nombre",
        align: "left",
        value: "Nombre1",
        sortable: false,
        class: "primary"
      },
      {
        text: "Cedula",
        align: "left",
        value: "NoIdentificacion",
        sortable: false,
        class: "primary"
      },
      {
        text: "Fecha Registro",
        value: "FechaRegistro",
        align: "left",
        sortable: false,
        class: "primary"
      },
      {
        text: "Estado",
        value: "Estatus",
        align: "left",
        sortable: false,
        class: "primary"
      },

      ],
      headersencuesta: [{
        text: "",
        align: "left",
        sortable: false,
        value: "name",
        class: "primary"
      },
      {
        text: "Encuesta",
        align: "left",
        sortable: false,
        value: "Encuesta",
        class: "primary"
      },
      {
        text: "Nombre",
        align: "left",
        value: "Nombre1",
        sortable: false,
        class: "primary"
      },
      {
        text: "Cedula",
        align: "left",
        value: "NoIdentificacion",
        sortable: false,
        class: "primary"
      },
      {
        text: "Fecha Registro",
        value: "FechaRegistro",
        align: "left",
        sortable: false,
        class: "primary"
      },
      {
        text: "Estado",
        value: "Estatus",
        align: "left",
        sortable: false,
        class: "primary"
      },

      ],
      user: '',
      message: '',
      messages: [],


    }
  },
  async mounted() {

    await this.$store.dispatch("asambleas/fetchasambleas");
    await this.$store.dispatch("asambleas/fetchmociones");
    this.filteredProducts = this.getasamblea.asamblea.Mo_Mociones.filter(a => a.IdEstado == 7 || a.IdEstado == 8);
    this.filteredProductsasign = this.getasamblea.asamblea.Mo_Mociones.filter(a => a.IdEstado == 6 || a.IdEstado == 5);
    this.filterencuesta = this.getasamblea.encuestas;
    this.interval = setInterval(this.updateCurrentTime, 1000);
    this.sockets.subscribe("QUORUMEMIT", (data) => {
      console.log('dataaaaa'+data)
    this.item2s.push(data)
    
    });
    //this.print()
  },
  components: {
    Carousel,
    Slide,
    VueEditor,
    moment
  },
  methods: {
    async print() {
      const el = this.$refs.printMe;
      // add option type to get the image version
      // if not provided the promise will return 
      // the canvas.
      const options = {
        type: 'dataURL'
      }
      this.output = await this.$html2canvas(el, options);
    },
    quorum(){
      if (confirm("Desea Realizar la llamada?")) {
        this.print()
      let inicc = moment()
     let hquorum = inicc.format('MM/DD/YYYY hh:mm');
      this.$socket.emit('QUORUM', {
        message: "primera llamada de Quorum",
        hora: hquorum
      });
    }
    },
    change1() {
      //console.log(this.votoselected);
      if (this.votoselected == true) {
        this.mocionvitacion.TpVoto = "1";
      } else {
        this.mocionvitacion.TpVoto = "0";
      }
    },
    async aprovarmoc(item) {
      if (confirm("Desea aprobar esta Moción?")) {


        let newmocion = await this.$store.dispatch('asambleas/aprobarmocion', item)
        console.log("mociooonn" + JSON.stringify(newmocion))
        const index = this.filteredProducts.findIndex(x => x.IdMocion === item);
        this.filteredProducts.splice(index, 1)
        this.filteredProductsasign.push(newmocion)


      }


    },
    async descmocion(item) {
      if (confirm("Desea descartar esta Moción?")) {
        let quitarmocion = await this.$store.dispatch('asambleas/descartarmocion', item)
        //console.log("mociooonn"+JSON.stringify(quitarmocion))
        this.filteredProducts.pop(quitarmocion)
      }
    },
    async votacionmocion(id,titulo) {
      let fin = moment()
      this.mocionvitacion.HoraInicio = fin.format('MM/DD/YYYY hh:mm');
      let hr = fin.format('MM/DD/YYYY hh:mm:ss');
      //let min = moment().add(this.mocionvitacion.TiempoVotacion, 'm');
      //let minf =min.format('MM/DD/YYYY hh:mm:ss');
      this.mocionvitacion.IdMocion = id
      if (confirm("Desea iniciar la votacion de esta Moción?")) {
        //let votacionmociones = await this.$store.dispatch('asambleas/votacionmocion', this.mocionvitacion)

        this.$socket.emit('SEND_MESSAGE', {
          id: id,
          titulo: titulo,
          horainicio: hr,
          horafin: this.mocionvitacion.TiempoVotacion
        });
        //console.log(votacionmociones)
        this.dialog = false
        //this.filteredProducts.pop(quitarmocion)
      }
    },
    async votacionEncuesta(id,titulo) {
      let fin = moment()
      this.mocionvitacion.HoraInicio = fin.format('MM/DD/YYYY hh:mm');
      let hr = fin.format('MM/DD/YYYY hh:mm:ss');
      //let min = moment().add(this.mocionvitacion.TiempoVotacion, 'm');
      //let minf =min.format('MM/DD/YYYY hh:mm:ss');
      this.mocionvitacion.IdMocion = id
      if (confirm("Desea iniciar la votacion de esta Encuesta?")) {
        //let votacionmociones = await this.$store.dispatch('asambleas/votacionmocion', this.mocionvitacion)

        this.$socket.emit('VOTACIONENCUESTA', {
          id: id,
          titulo: titulo,
          horainicio: hr,
          horafin: this.mocionvitacion.TiempoVotacion
        });
        //console.log(votacionmociones)
        this.dialog = false
        //this.filteredProducts.pop(quitarmocion)
      }
    },
    moment: function () {
      return moment();
    },
    reset: function () {
      this.$data.state = "started";
      this.$data.startTime = Date.now();
      this.$data.currentTime = Date.now();
    },
    pause: function () {
      this.$data.state = "paused";
      let date = new Date();
      let value = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
      this.horainicio = value;
      this.ftechhorainicio = moment()
    },
    resume: function () {
      let fin = moment()
      this.ftechhorafin = fin.format('h:mm:ss')
    },
    updateCurrentTime: function () {
      if (this.$data.state == "paused") {
        this.currentTime = Date.now();
      }
    }
  },
  filters: {
    moment: function (value) {
      return moment(String(value)).format('MM/DD/YYYY hh:mm')
    }
  },
  computed: {
    ...mapGetters({
      getasamblea: 'asambleas/asambleasget',
      getmociones: 'asambleas/mocionesget',
    }),
    time: function () {
      return this.hours + ':' + this.minutes + ':' + this.seconds;
    },
    milliseconds: function () {
      return this.currentTime - this.$data.startTime;
    },
    hours: function () {
      var lapsed = this.milliseconds;
      var hrs = Math.floor((lapsed / 1000 / 60 / 60));
      return hrs >= 10 ? hrs : '0' + hrs;
    },
    minutes: function () {
      var lapsed = this.milliseconds;
      var min = Math.floor((lapsed / 1000 / 60) % 60);
      return min >= 10 ? min : '0' + min;
    },
    seconds: function () {
      var lapsed = this.milliseconds;
      var sec = Math.ceil((lapsed / 1000) % 60);
      return sec >= 10 ? sec : '0' + sec;
    }
  },
  destroyed: function () {
    clearInterval(this.interval)
  },
}