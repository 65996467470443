<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-tile v-bind="attrs" v-on="on" @click="setTest()">
        <v-list-tile-title>Editar</v-list-tile-title>
        <v-list-tile-action>
          <v-icon color="blue darken-2">fa fa-pencil</v-icon>
        </v-list-tile-action>
      </v-list-tile>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Editar Pregunta </v-toolbar-title>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader></v-subheader>
        <div class="list-item-mod">
          <v-app id="inspire">
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      disabled
                      label="Cve. Pregunta"
                      outline
                      v-model="patchTest.CvePregunta"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <datepicker
                      :inactive="true"
                      labelpiker="Fecha Registro"
                      :date="patchTest.FechaRegistro"
                      @input="patchTest.FechaRegistro = $event"
                    ></datepicker>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select
                      :items="asamblea_state(estados)"
                      label="Estado"
                      outline
                      v-model="forGetIdState"
                      @input="getIdEstado"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      disabled
                      label="Registrado Por:"
                      outline
                      :value="user.Nombre1"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container>
                <v-flex xs12 sm6 md3>
                  <v-text-field
                    disabled
                    label="Atendido Por:"
                    outline
                  ></v-text-field>
                </v-flex>

                <v-subheader>Descripción</v-subheader>
                <hr />
                <vue-editor v-model="patchTest.Pregunta" />
              </v-container>

              <v-container class="d-flex justify-space-between">
                <v-flex xs12 sm6 md3 class="mx-auto text-xs-center">
                  <v-btn color="successs" @click="editTest()"> Grabar </v-btn>
                </v-flex>
              </v-container>
            </v-form>
          </v-app>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>


  <script>
import { mapGetters, mapState } from "vuex";
import datepicker from "@/components/datepicker.vue";
import axios from "axios";

export default {
  name: "editTest",
  props: ["pregunta"],
  components: { datepicker },
  mounted() {},
  data() {
    return {
      tableRows: [],
      counter: 0,
      forGetIdState: null,
      notifications: false,
      dialog: false,
      sound: true,
      widgets: false,
      e7: [],
      patchTest: {
        CvePregunta: "",
        FechaRegistro: "",
        IdEstado: null,
        Pregunta: "",
        IdUsuario: "",
        Quorum: 0,
      },
    };
  },
  methods: {
    setTest() {
      let pregunta = this.pregunta;
      this.patchTest.CvePregunta = pregunta.CvePregunta;
      this.patchTest.FechaRegistro = pregunta.FechaRegistro;
      this.setEstado(pregunta.IdEstado);
      this.patchTest.IdEstado = pregunta.IdEstado;
      this.patchTest.Pregunta = pregunta.Pregunta;
      this.patchTest.IdUsuario = pregunta.IdUsuario;
      this.patchTest.Quorum = pregunta.Quorum;
    },
    async editTest() {
      let pregunta = this.pregunta;
      console.log(this.patchTest);
      await axios
        .patch(
          process.env.VUE_APP_BASE_URL + `/preguntas/${pregunta.IdPregunta}`,
          this.patchTest
        )
        .then(() => {
          alert("Pregunta actualizada exitosamente");
          this.$store.dispatch("preguntas/fetchpreguntas");
          this.dialog = false;
        })
        .catch(() => {
          alert("Error del sistema, por favor contacte a su administrador");
        });
    },
    getIdEstado() {
      for (let i = 0; i < this.estados.length; i++) {
        if (this.estados[i].Estado == this.forGetIdState) {
          this.patchTest.IdEstado = this.estados[i].IdEstado;
        }
      }
    },
    setEstado(id) {
      for (let i = 0; i < this.estados.length; i++) {
        if (this.estados[i].IdEstado == id) {
          this.forGetIdState = this.estados[i].Estado;
        }
      }
    },
    asamblea_state(estados) {
      let getEstados = estados.map((estado) => {
        return estado.Estado;
      });
      return getEstados;
    },
    addTableRow: function () {
      this.counter++;
      this.tableRows.push("#" + this.counter + ":");
    },
    deleteTableRow: function (idx) {
      this.counter--;
      this.tableRows.splice(idx, 1);
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    ...mapState("preguntas", ["estados"]),
  },
};
</script >;


