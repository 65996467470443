<template>
  <v-layout>
    <v-btn flat> modal tipo 1 </v-btn>

    <v-dialog v-model="dialog" persistent max-width="500">
      <v-container class="primary" titulo-bg grid-list-md full-height>
        <v-layout row wrap align-center>
          <v-flex>
            <h2 class="text-xs-center">Votación de Moción</h2>
          </v-flex>
        </v-layout>
      </v-container>

      <!-- <v-card-title class="headline">Alerta de Mocion</v-card-title> -->
      <br />
      <v-layout row>
        <v-card flat class="mx-auto text-xs-center">
          <span
            ><v-icon center x-large color="green">fa fa-bell-o</v-icon></span
          >
          <!--  <v-card flat><h1>Mocion</h1></v-card> -->
        </v-card>
      </v-layout>
      <v-layout row>
        <v-card flat class="mx-auto text-xs-center">
          <vac
            @finish="dialog = false"
            :left-time="6000"
            :auto-start="false"
            ref="vac1"
          >
            <template>
              <v-layout row wrap>
                <v-flex>
                  <v-card flat class="mx-auto text-xs-center">
                    <v-card-text class="mx-auto text-xs-center">
                      <h3>Tiempo restante:</h3>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 sm6 md6 offset-sm3>
                  <v-card dark color="blue" class="rounded-card">
                    <v-card-text>{{ countdown }}</v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
            </template>
          </vac>
        </v-card>
      </v-layout>
      <v-container fill-height grid-list>
        <v-layout row>
          <v-flex offset-xs1 xs10 class="mx-auto text-xs-center">
            <p class="texto-borde">
              <span v-html="titulo"></span>
            </p>
          </v-flex>
        </v-layout>
      </v-container>
      <v-layout row>
        <v-card-actions class="mx-auto text-xs-center">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Si</v-btn>
          <v-btn color="red white--text" text @click="dialog = false">No</v-btn>
          <v-btn color="warning" text @click="play">Abstenerse</v-btn>
        </v-card-actions>
      </v-layout>
    </v-dialog>
  </v-layout>
</template>





<script>
import * as moment from "moment";
import x from "@/assets/sms.mp3";
export default {
  data() {
    return {
      titulo:null,
      seconds: null,
      countdown: null,
      expires_in: null,
      interval: null,
      dialog: false,
      alerta: require("@/assets/alerta2.gif"),
    };
  },
  methods: {
    play() {
      var audio = new Audio(x);
      audio.play();
    },
    _setInterval: function () {
      this.interval = setInterval(() => {
        if (this.expires_in === 0) {
          clearInterval(this.interval);
          this.dialog = false;
        } else {
          this.expires_in -= 1;
          this.countdown = moment
            .utc(this.expires_in * 1000)
            .subtract(1, "seconds")
            .format("HH:mm:ss");
        }
      }, 1000);
    },
  },
  mounted() {
    this.sockets.subscribe("MESSAGE", (data) => {
      //this.messages = [...this.messages, data];
      this.titulo=data.titulo
      //console.log("holiaaaaaaa" + data.user);
      this.seconds = data.horafin * 60;
      //console.log("holiaaaaaaa22" + data.horafin * 60);
      var audio = new Audio(x);
      audio.muted = false;
      audio.play();
      this.dialog = true;

      // you can also do this.messages.push(data)

      this.countdown = moment.utc(this.seconds).format("HH:mm:ss");
      this.expires_in = this.seconds;
      this._setInterval();
    });
  },
  destroyed() {
    clearInterval(this.interval);
    this.dialog = false;
  },
};
</script>
<style scoped>
.titulo-bg {
  background-color: #2196f3;
  color: white;
}
.texto-borde {
  border-width: 2px;
  border-style: solid;
  border-color: #2a7972;
  border-radius: 6px;
  padding: 5px;
}
</style>