<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <v-list-tile v-bind="attrs" v-on="on">
        <v-list-tile-action>
          <v-list-tile-title>Eliminar</v-list-tile-title>
        </v-list-tile-action>
        <v-list-tile-content
          ><v-icon color="orange darken-2">fa fa-trash</v-icon>
        </v-list-tile-content>
      </v-list-tile>
    </template>
    <v-card>
      <v-card-title class="headline">
        ¿Desea eliminar esta pregunta?
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="borrar()"> Aceptar </v-btn>
        <v-btn color="red darken-1" text @click="dialog = false">
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  name: "eliminarPregunta",
  props: ["pregunta"],
  data() {
    return {
      dialog: false,
    };
  },

  methods: {
    async borrar() {
      alert("Eaerqdfdfdfui");
      let pregunta = this.pregunta;

      let responseDeleteTest = await axios
        .delete(
          process.env.VUE_APP_BASE_URL + `/preguntas/${pregunta.IdPregunta}`
        )
        .then(() => {
          this.$store.dispatch("preguntas/fetchpreguntas");
          this.dialog = false;
        })
        .catch(() => {
          alert("Error del sistema, por favor contacte a su administrador");
        });
      responseDeleteTest = responseDeleteTest.data.payload;
      console.log(responseDeleteTest);
    },
  },
};
</script>
