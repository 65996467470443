import axios from "axios";

export default {
  namespaced: true,
  state: {
    preguntas: [],
    estados: []
  },
  mutations: {
    SET_PREGUNTAS(state, pregunta) {
      state.preguntas = pregunta;
    },
    SET_STATES(state, estados) {
      state.estados = estados;
    }
  },
  actions: {
    async fetchpreguntas({ dispatch }) {
      let response = await axios.get(
        process.env.VUE_APP_BASE_URL + "/preguntas"
      );
      return dispatch("attemptpreguntas", response.data.payload);
    },
    async attemptpreguntas({ commit, state }, pregunta) {
      console.log(pregunta);
      if (pregunta) {
        commit("SET_PREGUNTAS", pregunta);
      }
      if (!state.preguntas) {
        return;
      }
    },
    async fetchestados({ dispatch }) {
      let response = await axios.get(
        process.env.VUE_APP_BASE_URL + "/estado/?Catalo=PREGUNTAS"
      );
      return dispatch("attemptestados", response.data.payload);
    },
    async attemptestados({ commit, state }, estados) {
      if (estados) {
        commit("SET_STATES", estados);
      }
      if (!state.estados) {
        return;
      }
    }
  }
};
