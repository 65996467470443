import axios from "axios";

export default {
  namespaced: true,
  state: {
    idEdMo: null,
    dialog: false,
    mociones: [],
    estados_mociones: []
  },
  mutations: {
    SET_MOCIONES(state, mocion) {
      state.mociones = mocion;
    },
    SET_STATES(state, estados_mociones) {
      state.estados_mociones = estados_mociones;
    }
  },
  actions: {
    async fetchmociones({ dispatch }) {
      let response = await axios.get(process.env.VUE_APP_BASE_URL + "/mocion");
      return dispatch("attemptmociones", response.data.payload);
    },
    async attemptmociones({ commit, state }, mocion) {
      if (mocion) {
        commit("SET_MOCIONES", mocion);
      }
      if (!state.mociones) {
        return;
      }
    },
    async fetchestados({ dispatch }) {
      let response = await axios.get(process.env.VUE_APP_BASE_URL + "/estado");
      return dispatch("attemptestados", response.data.payload);
    },
    async attemptestados({ commit, state }, estados) {
      let estados_mociones = [];
      estados.map(estado => {
        if (estado.Catalo == "MOCIONES") {
          estados_mociones.push(estado);
        }
      });
      if (estados_mociones) {
        commit("SET_STATES", estados_mociones);
      }
      if (!state.estados) {
        return;
      }
    }
  }
};
