import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vuetify from "vuetify";
import Vuelidate from "vuelidate";
import VueApexCharts from "vue-apexcharts";
import "vuetify/dist/vuetify.min.css"; // Ensure you are using css-loader
import "./Style/main.css";
import "font-awesome/css/font-awesome.min.css";
import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";
import vueAwesomeCountdown from "vue-awesome-countdown";
import IdleVue from "idle-vue";
import Axios from "axios";
import Vue2Editor from "vue2-editor";
import SequentialEntrance from "vue-sequential-entrance";
import "vue-sequential-entrance/vue-sequential-entrance.css";
import moment from "moment";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSocketIO from 'vue-socket.io'
import VueFriendlyIframe from 'vue-friendly-iframe';
import VueHtml2Canvas from 'vue-html2canvas';
 
Vue.use(VueHtml2Canvas);

Vue.component('loading-overlay', Loading)

require("@/store/modules/usuariosub");

Vue.use(VueFriendlyIframe);
Vue.use(vueAwesomeCountdown, "vac");
Vue.use(VueFileAgent);
Vue.use(moment);
Vue.use(SequentialEntrance);
Vue.use(Vuelidate);
Vue.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_BASE_URL_SOCKET,
  vuex: {
    store,
  },
}))
Vue.prototype.$http = Axios;

store.dispatch("auth/attempt", localStorage.getItem("token"));
store
  .dispatch("auth/attempt1", JSON.parse(localStorage.getItem("usuario")))
  .then(() => {
    new Vue({
      router,
      store,
      beforeCreate() {
        //console.log('configurando socket')
       // console.log(this.$socket)
        store.commit('SET_SOCKET', this.$socket);
      },
      render: h => h(App)
    }).$mount("#app");
   
  });

  
let color =  localStorage.getItem("tema");
//console.log("color" + color);
Vue.use(VueApexCharts);
Vue.use(Vue2Editor);
Vue.use(Vuetify, {
  iconfont: "fa4",
  theme: {
    primary: color ? color : "#212121",
    secondary: "#b0bec5",
    accent: "#32b294",
    error: "#b71c1c"
  }
});
Vue.component("apexchart", VueApexCharts);
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: process.env.VUE_APP_TIME_INACTIVITY,
  startAtIdle: false,
  store
});

Vue.config.productionTip = false;
