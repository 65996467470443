import axios from "axios";

export default {
  namespaced: true,
  state: {
    posts: [],
    asambleas: [],
    mociones: [],
    asambleacurso:null,
   
  },

  mutations: {
    SET_ASAMBLEA(state, posts) {
      //console.log('dfgdf' + posts)
      state.posts = posts;
    },
    SET_ASAMBLEACURSO(state, asambleacurso) {
      //console.log('dfgdf' + posts)
      state.asambleacurso = asambleacurso;
    },
    SET_MOCIONES(state, mociones) {
      //console.log('dfgdf' + posts)
      state.mociones = mociones;
    },
    EDIT_MOCION(state, newmocion) {
      state.posts.Mo_Mociones.forEach(i => {
        if(i.IdMocion==newmocion.IdMocion){
          //console.log('nueva'+newmocion.IdEstado)
          i=newmocion;
        }
      });
     
    },
    DESCARTAR_MOCION(state, quitarmocion) {
      state.posts.Mo_Mociones.forEach(i => {
        if(i.IdMocion==quitarmocion.IdMocion){
         // console.log('nueva'+quitarmocion.IdEstado)
          i=quitarmocion;
        }
      });
     
    },
    /**
     * Setea las asambleas
     */
    SET_ASAMBLEAS(state, asambleas) {
      asambleas.map(asamblea => {
        if (asamblea.Ct_Usuario) {
          asamblea.Ct_Usuario.nombreCompleto = "";
          asamblea.Ct_Usuario.nombreCompleto += asamblea.Ct_Usuario.Nombre1;
          if (asamblea.Ct_Usuario.Nombre2) {
            asamblea.Ct_Usuario.nombreCompleto += ` ${asamblea.Ct_Usuario.Nombre2}`;
          }
          asamblea.Ct_Usuario.nombreCompleto += ` ${asamblea.Ct_Usuario.PrimerApellido}`;
          if (asamblea.Ct_Usuario.SegundoApellido) {
            asamblea.Ct_Usuario.nombreCompleto += ` ${asamblea.Ct_Usuario.SegundoApellido}`;
          }
        }
      });
      state.asambleas = asambleas;
    }
  },
  actions: {
    async fetchasambleaactual({ dispatch }) {

      let response = await axios.get(process.env.VUE_APP_BASE_URL + '/asamblea?Estado=EN CURSO');
      //console.log(response.data.payload);
      return dispatch("setasamblealocal", response.data.payload);
    },
    async setasamblealocal({ commit, state }, asambleacurso) {
      if (asambleacurso) {
        commit('SET_ASAMBLEACURSO', asambleacurso)
      }
      if (!state.asambleacurso) {

        return
      }


    },
    async fetchasambleas({ dispatch }) {
      let response = await axios.get(
        process.env.VUE_APP_BASE_URL + `/asamblea/5186`
      );
      return dispatch("attempt", response.data.payload);
    },
    async attempt({ commit, state }, posts) {
      //console.log("asambleas:" + posts);
      if (posts) {
        commit("SET_ASAMBLEA", posts);
      }
      if (!state.posts) {
        return;
      }
    },
    async fetchmociones({ dispatch }) {
      let response = await axios.get(process.env.VUE_APP_BASE_URL + "/mocion");
      return dispatch("attemptmociones", response.data.payload);
    },
    async attemptmociones({ commit, state }, mociones) {
      if (mociones) {
        commit("SET_MOCIONES", mociones);
      }
      if (!state.mociones) {
        return;
      }

    },

   
    ///////aprobar mociones
    async aprobarmocion({ commit }, idvideo) {
      //console.log(idvideo)
      let response = await axios.patch(process.env.VUE_APP_BASE_URL + '/mocion/' + idvideo,{"IdEstado":"6"})
      let newmocion=response.data.payload
      commit('EDIT_MOCION', newmocion)
      return newmocion;
    },
    async descartarmocion({ commit }, idvideo) {
     // console.log(idvideo)
      let response = await axios.patch(process.env.VUE_APP_BASE_URL + '/mocion/' + idvideo,{"IdEstado":"8"})
      let quitarmocion=response.data.payload
      commit('DESCARTAR_MOCION', quitarmocion)
      return quitarmocion;
    },
    async votacionmocion({ commit }, mocionvitacion) {
      //console.log(mocionvitacion)
      let response = await axios.patch(process.env.VUE_APP_BASE_URL + `/mocion/${mocionvitacion.IdMocion}`, mocionvitacion)
      commit('EDIT_USER', mocionvitacion)
      console.log(response.data);
    },
    
  },
  getters: {
    asambleasget(state) {
      //console.log('getsamblea:' + state.posts.IdAsamblea)
      return state.posts;
    },
    mocionesget(state) {
      //console.log('getsamblea:' + state.posts.IdAsamblea)
      return state.mociones;
    },
    asableacursoget(state) {
      //console.log('getsamblea:' + state.posts.IdAsamblea)
      return state.asambleacurso;
    }
  }
};
