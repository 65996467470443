<template>
  <v-app id="inspire">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#32b294"
      loader="dots"
    ></loading>

    <menuapp></menuapp>

    <v-content>
      <v-snackbar v-model="snackbar" right top :timeout="10000">
        {{ quorum }}
        <v-btn color="pink" flat @click="snackbar = false"> Cerrar </v-btn>
      </v-snackbar>
      <v-flex>
        <div id="app">
          <router-view />
        </div>
      </v-flex>
    </v-content>
    <snackbar
      v-if="this.$route.path !== '/' && this.$route.path !== '/recoverpass'"
      :snack="snack"
      v-on:snack="handleSnack($event)"
    ></snackbar>
  </v-app>
</template>

<script>
import menuapp from "@/components/menu.vue";
import snackbar from "@/components/common/snackbar.vue";
import { mapGetters } from "vuex";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    menuapp,
    snackbar,
    Loading,
  },
  data() {
    return {
      snackbar: false,
      mode: "",
      quorum: null,
      hor: null,
      alert22: false,
      snack: {
        show: false,
        timeout: 2000,
        btn_color: "pink",
        btn_text: "test",
        text: "¡Éxito!",
      },
    };
  },
  methods: {
   handler() {
     //console.log('adios')
    }
  },
  watch: {
    expired: function () {
      this.snack.show = true;
      this.snack.timeout = 5000;
      this.snack.btn_color = "red";
      this.snack.btn_text = "OK";
      this.snack.text = "Tiempo de inactividad detectado";
    },
  },
  computed: {
    expired() {
      return this.$store.state.idleVue.isIdle;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  mounted() {
     
    this.sockets.subscribe("QUORUM_ALERT", (data) => {
      //console.log(data.message + "quoroo");
      this.quorum = data.message;
      this.hor = data.hora;
      this.snackbar = true;
    });
     
  },
  created() {
    window.addEventListener('beforeunload', this.handler)
    
  },
  

};
</script>
