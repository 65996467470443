<template>
  <div
    class="routing"
    v-if="this.$route.path !== '/' && this.$route.path !== '/recoverpass'"
  >
    <v-toolbar app color="primary" class="hidden-xs-and-down white--text">
      <v-toolbar-side-icon v-if="user.Ct_TpPadron.Junta == 'S'"
        @click.stop="drawer = !drawer"
        class="white--text"
      ></v-toolbar-side-icon>
      <v-toolbar-title
        ><h3>
          <img
            v-if="logo !== null"
            :src="'https://asamblea.back-end.com.mx/' + logo"
            :key="logo"
            class="mr-3"
            height="45"
          />
          <img
            v-else
            src="https://randomuser.me/api/portraits/men/85.jpg"
          /></h3
      ></v-toolbar-title>
      <v-spacer></v-spacer>
       <v-flex xs5 class="text-xs-center">
                  <marquee-text :repeat="5" :duration="20">
           -{{quorum}} -  
          </marquee-text>
    </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs4 class="text-xs-center">
        <v-menu>
          <template #activator="{ on: menu }">
            <template>
              <v-btn color="white" dark flat v-on="{ ...menu }">
                <v-list-tile-avatar>
                  <img
                    v-if="user.FotoPerfil !== null"
                    :src="'https://asamblea.back-end.com.mx/' + user.FotoPerfil"
                    :key="user.FotoPerfil"
                    class="avatar"
                  />
                  <img
                    v-else
                    src="https://randomuser.me/api/portraits/men/85.jpg"
                    class="avatar"
                  />
                </v-list-tile-avatar>
                {{ user.Nombre1
                }}<v-icon right dark>fa fa-chevron-down</v-icon></v-btn
              >
            </template>
          </template>
          <v-list>
            <v-list-tile @click="dialog = true">
              <v-list-tile-action>
                <v-icon>fa fa-user</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Perfil</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>

            <logout></logout>
          </v-list>
        </v-menu>
      </v-flex>
    </v-toolbar>
    <template v-if="authenticated">
      <v-navigation-drawer
        v-model="drawer"
        dark
        fixed
        app
        clipped-right
        v-if="user.Ct_TpPadron.Junta == 'S'"
      >
        <v-list dense>
          <v-list-tile
            class="primary"
            style="margin: 0px; padding: 0px; height: 63px"
          >
            <v-list-tile-action> </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title><h2>Asambleas</h2></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile
            @click.stop="left = !left"
            v-for="item in nav"
            :key="item.title"
            :to="item.link"
            :title="item.title"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{ item.text }}</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!--catalogos -->
          <v-list-group>
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>Catálogos</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile to="/padron">
              <v-list-tile-action> </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Tipos de Pefiles</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile to="/usuarios">
              <v-list-tile-action> </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Padrón</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group>
          <!--asambleas -->
          <v-list-group>
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>Asambleas</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile to="/asambleas">
              <v-list-tile-action> </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Administración</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile to="/gestion-asamblea">
              <v-list-tile-action> </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Gestión</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <!--<v-list-tile to="/gestion-asamblea-escritorio">
              <v-list-tile-action> </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>
                  Escritorio de participantes
                </v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile to="/resultado-votacion">
              <v-list-tile-action> </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Resultado Votación</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile> -->
          </v-list-group>
          <!--mociones -->
          <v-list-tile to="/mociones">
            <v-list-tile-content>
              <v-list-tile-title>Mociones</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!--mociones -->
          <v-list-tile to="encuestas">
            <v-list-tile-content>
              <v-list-tile-title>Encuestas</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!--mociones -->
          <v-list-tile to="elecciones">
            <v-list-tile-content>
              <v-list-tile-title>Elecciones</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <!--mociones -->
          <v-list-tile to="/preguntas">
            <v-list-tile-content>
              <v-list-tile-title>Preguntas</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>

          <!--rifas -->
          <!--<v-list-group>
            <template v-slot:activator>
              <v-list-tile>
                <v-list-tile-title>Rifas</v-list-tile-title>
              </v-list-tile>
            </template>
            <v-list-tile to="">
              <v-list-tile-action> </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Inventario</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile to="">
              <v-list-tile-action> </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Admon Rifas</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list-tile to="">
              <v-list-tile-action> </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>Sorteos</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list-group> -->
          <!--mociones -->
          <v-list-tile to="">
            <v-list-tile-content>
              <v-list-tile-title>Reportes y Estadisticas</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile to="">
            <v-list-tile-content>
              <v-list-tile-title>Monitor de Votaciones</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile to="/settings" v-if="user.Ct_TpPadron.Admin == 'S'">
            <v-list-tile-content>
              <v-list-tile-title>Configuración</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-navigation-drawer>
    </template>
    <v-dialog v-model="dialog" hide-overlay width="300">
      <v-card color="white">
        <v-card-text>
          <span
            >{{ user.Nombre1 }} {{ user.Nombre2 }} {{ user.PrimerApellido }}
            {{ user.SegundoApellido }}</span
          >
          <hr />
          <span class="primary--text">Votante</span>
          <p>
            <span>correo: {{ user.Email }}</span>
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import logout from "@/components/logout.vue";
import { mapGetters, mapActions } from "vuex";
import MarqueeText from 'vue-marquee-text-component'
export default {
  name: "menuapp",
  props: {
    msg: String,
  },
  components: {
    logout,
    MarqueeText
  },
  data() {
    return {
      quorum:null,
      dialog: false,
      drawer: null,
      drawerRight: null,
      right: false,
      left: false,
      nav: [
        {
          text: "Inicio",
          title: "Home",
          active: true,
          link: "/home",
        },
      ],
      admins: [
        ["Management", "fa fa-users"],
        ["Settings", "fa fa-cog"],
      ],
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      logo: "tema/logo",
    }),
  },
  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
    }),
    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace({
          name: "home",
        });
      });
    },
  },
 async mounted() {
   await this.$store.dispatch("tema/logo");
   
    this.sockets.subscribe("QUORUM_ALERT", (data) => {
      console.log(data.message+'quoroo')
      this.quorum=data.message
      this.hor=data.hora
      this.iconText= 'fa fa-bell'

    });
   
  },
};
</script>
