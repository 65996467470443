<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-tile v-bind="attrs" v-on="on" @click="setMotion()">
        <v-list-tile-title>Editar</v-list-tile-title>
        <v-list-tile-action>
          <v-icon color="blue darken-2">fa fa-pencil</v-icon>
        </v-list-tile-action>
      </v-list-tile>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Editar Mocion </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn color="primary" flat text @click="dialog = false">
            Guardar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader></v-subheader>
        <div class="list-item-mod">
          <v-app id="inspire">
            <v-form>
              <v-container>
                <v-layout row wrap>
                  <v-flex xs12 sm6 md3>
                    <v-text-field
                      disabled
                      label="No. Mocion"
                      outline
                      v-model="patchMotion.CveMocion"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <datepicker
                      :inactive="true"
                      labelpiker="Fecha Registro"
                      :date="patchMotion.FechaRegistro"
                      @input="patchMotion.FechaRegistro = $event"
                    ></datepicker>
                  </v-flex>
                  <v-flex xs12 sm6 md3>
                    <v-select
                      :items="asamblea_state(estados_mociones)"
                      label="Estado"
                      outline
                      v-model="forGetIdState"
                      @input="getIdEstado"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
              </v-container>
              <v-container>
                <v-subheader>Descripción</v-subheader>
                <hr />
                <vue-editor v-model="patchMotion.Mocion" />
              </v-container>
              <v-container class="d-flex justify-space-between">
                <v-flex xs12 sm6 md3>
                  <v-text-field
                    disabled
                    label="Registrado Por:"
                    outline
                    :value="user.Nombre1 + ' ' + user.PrimerApellido"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md3>
                  <v-btn color="primary" @click="editMotion()">
                    Grabar
                  </v-btn>
                </v-flex>
              </v-container>
            </v-form>
          </v-app>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>

  <script>
import { mapGetters, mapState } from "vuex";
import datepicker from "@/components/datepicker.vue";
import axios from "axios";

export default {
  name: "editMotion",
  props: ["mocion"],
  components: {
    datepicker
  },
  mounted() {},
  data() {
    return {
      forGetIdState: null,
      notifications: false,
      dialog: false,
      sound: true,
      widgets: false,
      e7: [],
      patchMotion: {
        CveMocion: "",
        FechaRegistro: "",
        IdEstado: null,
        Mocion: "",
        IdUsuario: "",
        Quorum: 0
      }
    };
  },
  methods: {
    setMotion() {
      let mocion = this.mocion;
      this.patchMotion.CveMocion = mocion.CveMocion;
      this.patchMotion.FechaRegistro = mocion.FechaRegistro;
      this.setEstado(mocion.IdEstado);
      this.patchMotion.IdEstado = mocion.IdEstado;
      this.patchMotion.Mocion = mocion.Mocion;
      this.patchMotion.IdUsuario = mocion.IdUsuario;
      this.patchMotion.Quorum = mocion.Quorum;
    },
    async editMotion() {
      let mocion = this.mocion;
      console.log(this.patchMotion);
      await axios
        .patch(
          process.env.VUE_APP_BASE_URL + `/mocion/${mocion.IdMocion}`,
          this.patchMotion
        )
        .then(() => {
          alert("Mocion actualizada");
          this.$store.dispatch("mociones/fetchmociones");
          this.dialog = false;
        })
        .catch(() => {
          alert("Ups! Algo anda mal");
        });
    },
    getIdEstado() {
      for (let i = 0; i < this.estados_mociones.length; i++) {
        if (this.estados_mociones[i].Estado == this.forGetIdState) {
          this.patchMotion.IdEstado = this.estados_mociones[i].IdEstado;
        }
      }
    },
    setEstado(id) {
      for (let i = 0; i < this.estados_mociones.length; i++) {
        if (this.estados_mociones[i].IdEstado == id) {
          this.forGetIdState = this.estados_mociones[i].Estado;
        }
      }
    },
    asamblea_state(estados_mociones) {
      let getEstados = estados_mociones.map(estado => {
        return estado.Estado;
      });
      return getEstados;
    }
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user"
    }),
    ...mapState("mociones", ["estados_mociones"])
  }
};
</script>