
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import adduser from "@/components/adduser.vue";
import edituser from "@/components/edituser.vue";
export default {
  name: 'usuarios',
  components: {
    adduser,
    'edituser': edituser
  }, data() {
    return {
      isLoading: false,
      fullPage: false,
      loader: "bars",
      showModal: false,
      search: '',
      pagination: {},
      selected: [],
      headers: [
        { text: 'Acciones', value: 'actions', sortable: false, class: "primary" },
        {
          text: 'Cedula',
          align: 'center',
          sortable: true,
          value: 'NoIdentificacion',
          class: "primary"
        },
        { text: 'Nombre', value: 'Nombre1', sortable: false, class: "primary" },
        { text: 'Puesto', value: 'puesto', sortable: false, class: "primary" },
        { text: 'Correo', value: 'Email', sortable: false, class: "primary" },
        { text: 'Estado', value: 'estado', sortable: true, class: "primary" }
      ],

    }
  },
  async mounted() {
    this.isLoading = true;
    await this.$store.dispatch("estatus/fetchasambleas");
    this.isLoading = false
  },
  computed: {
    ...mapGetters({
      getasamblea: 'estatus/asambleasget',
    }),
    ...mapActions({
      delete: 'estatus/desactivar'
    }),

    pages() {
      if (this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      ) return 0

      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
    },
  },
  methods: {

    deleteItem(item) {

      confirm('¿Seguro que desea desactivar al usuario seleccionado?') && this.$store.dispatch('estatus/desactivar', item);
    },
    

  },
  watch: {

  },


}