<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-tile v-bind="attrs" v-on="on" @click="editar(id)">
        <v-list-tile-title>Editar</v-list-tile-title>
        <v-list-tile-action>
          <v-icon color="blue darken-2">fa fa-pencil-square-o</v-icon>
        </v-list-tile-action>
      </v-list-tile>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Editar Usuario</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn color="white--text" flat text @click.native="submit">
            Guardar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader></v-subheader>
        <div class="list-item-mod">
          <v-app id="inspire">
            <v-tabs centered color="transparent" dark icons-and-text>
              <v-tabs-slider color="yellow"></v-tabs-slider>

              <v-tab href="#tab-1"> Datos Generales </v-tab>

              <v-tab href="#tab-2"> Cambiar Contraseña </v-tab>

              <v-tab href="#tab-3"> Foto de Perfil </v-tab>

              <v-tab-item :value="'tab-1'">
                <v-card flat>
                  <v-card-text>
                    <v-form @submit.prevent="submit">
                      <v-subheader>Nombre</v-subheader>
                      <v-divider></v-divider>
                      <v-container>
                        <v-layout row wrap>
                          <v-flex xs12 sm6 md3>
                            <v-text-field
                              label="Nombre"
                              outline
                              v-model="form.Nombre1"
                              required
                              maxlength="30"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6 md3>
                            <v-text-field
                              label="Segundo nombre"
                              outline
                              v-model="form.Nombre2"
                              maxlength="30"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6 md3>
                            <v-text-field
                              label="Apellido paterno"
                              outline
                              v-model="form.PrimerApellido"
                              maxlength="30"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6 md3>
                            <v-text-field
                              label="Apellido Materno"
                              outline
                              v-model="form.SegundoApellido"
                              maxlength="30"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <v-subheader>Informacion</v-subheader>
                      <hr />
                      <v-container>
                        <v-layout row wrap>
                          <v-flex xs12 sm6 md3>
                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="form.FechaNac"
                              persistent
                              lazy
                              full-width
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="form.FechaNac"
                                  label="Fecha de nacimiento"
                                  outline
                                  prepend-icon="fa fa-calendar"
                                  readonly
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker v-model="form.FechaNac" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn
                                  flat
                                  color="primary"
                                  @click="modal = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  flat
                                  color="primary"
                                  @click="$refs.dialog.save(form.FechaNac)"
                                  >OK</v-btn
                                >
                              </v-date-picker>
                            </v-dialog>
                          </v-flex>

                          <v-flex xs12 sm6 md3>
                            <v-select
                              :items="getsexo"
                              label="Género"
                              item-text="TpGenero"
                              outline
                              item-value="IdGenero"
                              v-model="form.IdGenero"
                            ></v-select>
                          </v-flex>

                          <v-flex xs12 sm6 md3>
                            <v-select
                              :items="tipodoc"
                              label="Tp Identificación"
                              item-text="Doc"
                              item-value="TpIde"
                              outline
                              maxlength="30"
                              type="tel"
                              v-model="form.TpIde"
                            ></v-select>
                          </v-flex>

                          <v-flex xs12 sm6 md3>
                            <v-text-field
                              label="Cédula/Dimex"
                              outline
                              v-model="form.NoIdentificacion"
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <v-container>
                        <v-layout row wrap>
                          <v-flex xs12 sm6 md4>
                            <v-select
                              :items="getprofesion"
                              label="Profesión"
                              item-text="Profesion"
                              item-value="IdProfesion"
                              outline
                              v-model="form.IdProfesion"
                            ></v-select>
                          </v-flex>

                          <v-flex xs12 sm6 md4>
                            <v-select
                              :items="filteredProducts"
                              label="Puesto/Perfil"
                              item-text="TpPadron"
                              item-value="IdPadron"
                              outline
                              v-model="form.IdPadron"
                            ></v-select>
                          </v-flex>

                          <v-flex xs12 sm6 md4>
                            <v-select
                              :items="Estado"
                              label="Estado"
                              item-text="Estado"
                              item-value="IdEstado"
                              outline
                              v-model="form.IdEstado"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <!-- -->
                      <v-subheader>Direccion</v-subheader>
                      <hr />
                      <v-container>
                        <v-layout row wrap>
                          <!--  <v-flex xs12 sm6 md3>
                            <v-select
                              :items="getpostal"
                              label="Provincia"
                              :item-text="(items) => `${items.Provincia}`"
                              item-value="CodigoPostal"
                              outline
                              v-model="cantones"
                              @change="change1"
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md3>
                            <v-select
                              :items="getpostal"
                              label="Canton"
                              :item-text="(items) => `${items.Canton}`"
                              item-value="CodigoPostal"
                              outline
                              v-model="cantones"
                              @change="change1"
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md3>
                            <v-select
                              :items="getpostal"
                              label="Distrito"
                              :item-text="(items) => `${items.Distrito}`"
                              item-value="CodigoPostal"
                              outline
                              v-model="cantones"
                              @change="change1"
                            ></v-select>
                          </v-flex>
                          <v-flex xs12 sm6 md3>
                            <v-select
                              :items="getpostal"
                              label="Codigo Postal"
                              :item-text="(items) => `${items.CodigoPostal}`"
                              item-value="CodigoPostal"
                              outline
                              v-model="form.CodigoPostal"
                              @change="change1"
                            ></v-select>
                          </v-flex> -->
                          <v-flex xs12 sm6 md3>
                            <v-textarea
                              outline
                              name="input-7-4"
                              label="Ingrese direccion"
                              placeholder="Ingrese direccion"
                              v-model="form.Direccion"
                              maxlength="200"
                            ></v-textarea>
                          </v-flex>
                        </v-layout>
                      </v-container>
                      <!-- -->
                      <v-subheader>Contacto</v-subheader>
                      <hr />
                      <v-container>
                        <v-layout row wrap>
                          <v-flex xs12 sm6 md6>
                            <v-text-field
                              label="Email"
                              outline
                              maxlength="30"
                              v-model="form.Email"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm6 md6>
                            <v-text-field
                              outline
                              label="Movil"
                              maxlength="10"
                              type="tel"
                              v-model="form.Movil"
                            ></v-text-field>
                            <input
                              type="hidden"
                              label="IdUsuario"
                              v-model="form.IdUsuario"
                            />
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-2'">
                <v-card flat>
                  <v-card-text>
                    <v-subheader>Seguridad</v-subheader>
                    <hr />
                    <v-container>
                      <v-layout row wrap>
                        <v-flex xs12 sm12 md12>
                         <!-- <v-text-field
                            label="Contraseña anterior"
                            outline
                            v-model="passwordant"
                          ></v-text-field>-->
                        </v-flex>
                        <v-flex xs12 sm6 md4>
                          <v-text-field
                            label="Contraseña"
                            outline
                            v-model="formpassword.ReContrasena"
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 sm6 md4>
                          <v-text-field
                            label="Confirmar Contraseña"
                            outline
                            v-model="formpassword.password"
                          ></v-text-field>
                        </v-flex>
                        <br />
                         <v-btn block class="primary" @click="editarpassword()">
                          Cambiar Contrasena
                        </v-btn>
                        <v-flex xs12 sm12 md6> </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-3'">
                <v-card flat>
                  <v-card-text>
                    <v-subheader>Seguridad</v-subheader>
                    <hr />
                    <v-container>
                      <v-layout row wrap>
                        <v-flex xs12 sm6 md4>
                          <input
                            type="file"
                            name="file"
                            id="file"
                            ref="file"
                            @change="onFileUpload"
                            accept="image/*"
                          />
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-app>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>

  <script>
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "adduser",
  props: ["id"],
  components: {},
  data() {
    return {
      filteredProducts: [],
      modal: false,
      cantones: null,
      selectedprovincia: null,
      FechaNac: new Date().toISOString().substr(0, 10),
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      Estado: [
        {
          Estado: "Activo",
          IdEstado: 9,
        },
        {
          Estado: "Inactivo",
          IdEstado: 10,
        },
      ],
      tipodoc: [
        {
          Doc: "Cédula",
          TpIde: 9,
        },
        {
          Doc: "Dimex",
          TpIde: 10,
        },
      ],
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      e7: [],
      FILE: null,
      passwordant: null,
      passwordanterior: null,
      form: {
        CveUsuario: "333445",
        Nombre1: null,
        Nombre2: null,
        PrimerApellido: null,
        SegundoApellido: null,
        IdGenero: null,
        TpIde: null,
        NoIdentificacion: null,
        IdPadron: null,
        Direccion: null,
        FechaNac: "1900-01-01",
        IdEstado: null,
        Email: null,
        CodigoPostal: "10101",
        Movil: null,
        Cedula: "x",
        IdUsuario: null,
        IdProfesion: null,
        //Contrasena: null,
        //ReContrasena: null
      },
      formpassword: {
        Cedula:null,
        password: null,
        ReContrasena: null
      }
    };
  },
  methods: {
    ...mapActions({
      edit: "adduser/edituser", editpass: "adduser/editpass",
    }),
    change1() {
      this.selectedprovincia = this.getpostal.find(
        (i) => i.CodigoPostal === this.form.CodigoPostal
      );
      //console.log(this.getpostal.find((i) => i.CodigoPostal === "10101"));
    },
    editar(props) {
      //alert(props.IdUsuario);
      this.form.Nombre1 = props.Nombre1;
      this.form.Nombre2 = props.Nombre2;
      this.form.PrimerApellido = props.PrimerApellido;
      this.form.SegundoApellido = props.SegundoApellido;
      this.form.IdGenero = props.Ct_Genero.IdGenero;
      this.form.IdGenero = props.Ct_Genero.IdGenero;
      this.form.TpIde = 9;
      this.form.IdProfesion = props.Ct_Profesion.IdProfesion;
      this.form.NoIdentificacion = props.NoIdentificacion;
      this.form.IdPadron = props.Ct_TpPadron.IdPadron;
      this.form.Direccion = props.Direccion;
      var mydate = new Date(props.FechaNac);
      var str = mydate.toISOString().slice(0, 10);
      this.form.FechaNac = str;
      this.form.IdEstado = 9;
      this.form.Email = props.Email;
      this.formpassword.Cedula = props.NoIdentificacion;
      this.form.CodigoPostal = props.CodigoPostal;
      this.cantones = props.CodigoPostal;
      this.form.Movil = props.Movil;
      this.passwordanterior = props.Contrasena;
      this.form.IdUsuario = props.IdUsuario;
    },
    editarpassword(){
      
        if( this.formpassword.password ==null || this.formpassword.ReContrasena==null){
          alert('los campos no pueden estar vacios')
        }else{
          if( this.formpassword.password == this.formpassword.ReContrasena){
          this.editpass(this.formpassword)
          .then(() => {
            alert('Contrasena cambiada con exito')
          }).catch((e) => {
          console.log(e)
          alert("Usuario o contraseña incorrectos.");
          });
        }
        else{
         //alert(this.formpassword.Contrasena+ this.formpassword.ReContrasena +this.formpassword.Email )
        alert('Las Contrasenas no coinciden, favor de validar ambos campos')
      }
      }
      
    },
    onFileUpload(event) {
      this.FILE = event.target.files[0];
      //console.log(this.FILE);
    },
    submit() {
      //console.log(this.form);
      if (this.form.Nombre1 != null) {
        if (this.form.Email != null) {
            this.edit(this.form)
              .then(() => {
                if (this.FILE == null) {
                  alert("Usuario Editado con exito");
                  window.location.reload();
                } else {
                  const formData = new FormData();
                  formData.append("FotoPerfil", this.FILE, this.FILE.name);
                  axios
                    .patch(
                      process.env.VUE_APP_BASE_URL +
                        `/usuarios-update-foto-perfil/${this.form.IdUsuario}`,
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((resp) => {
                      console.log(resp);
                      //this.imagePath = resp.data.path;
                      alert("Usuario Editado con exito");
                      window.location.reload();
                    })
                    .catch(() => {
                      //console.log("fail");
                      alert(
                        "Error al crear usuario, intntelo de nuevo mas tarde "
                      );
                    });
                }
              })
              .catch((e) => {
                console.log(e);
                alert("Usuario o contraseña incorrectos.");
              });
            this.errors = [];
         
        } else {
          alert("la contraseña anterior no coincide");
        }
      } else {
        //this.form.Contrasena = this.passwordanterior;
        //this.form.ReContrasena = this.passwordanterior;

        this.edit(this.form)
          .then(() => {
            if (this.FILE == null) {
              alert("Usuario Editado con exito");
              window.location.reload();
            } else {
              const formData = new FormData();
              formData.append("FotoPerfil", this.FILE, this.FILE.name);
              axios
                .patch(
                  process.env.VUE_APP_BASE_URL +
                    `/usuarios-update-foto-perfil/${this.form.IdUsuario}`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then((resp) => {
                  console.log(resp);
                  //this.imagePath = resp.data.path;
                  alert("Usuario Editado con exito");
                  window.location.reload();
                })
                .catch(() => {
                  //console.log("fail");
                  alert("Error al crear usuario, intntelo de nuevo mas tarde ");
                });
            }
          })
          .catch((e) => {
            console.log(e);
            alert("Usuario o contraseña incorrectos.");
          });
        this.errors = [];
      }
    },
  },
  computed: {
    ...mapGetters({
      getsexo: "adduser/sexoget",
      getprofesion: "adduser/profesionget",
      getpostal: "adduser/postalget",
      getpadron: "adduser/padronget",
    }),
  },
  mounted() {
    this.$store.dispatch("adduser/fetchsexo");
    this.$store.dispatch("adduser/fetchprofesion");
    this.$store.dispatch("adduser/fetchpostal");
    this.$store.dispatch("adduser/fetchpadron");
    this.filteredProducts = this.getpadron.filter((product) =>
      product.Activo.includes("S")
    );
  },
};
</script>