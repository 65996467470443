<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="primary" v-bind="attrs" v-on="on">
        <v-icon left dark>fa fa-plus</v-icon>
        <span class="white--text"> Perfil</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>fa fa-times</v-icon>
        </v-btn>
        <v-toolbar-title>Agregar Perfil</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn color="white" flat text @click.native="submit">
            Guardar
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-list three-line subheader>
        <v-subheader></v-subheader>
        <div class="list-item-mod">
          <v-app id="inspire">
            <v-card flat>
              <v-card-text>
                <v-form @submit.prevent="submit">
                  <v-subheader>Nombre</v-subheader>
                  <v-divider></v-divider>
                  <v-container>
                    <v-layout row wrap>
                      <v-flex xs12 sm6 md3>
                        <v-text-field
                          label="Nombre"
                          outline
                          v-model="form.TpPadron"
                          required
                          maxlength="30"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-container>

                  <v-subheader>Configuración</v-subheader>
                  <v-divider></v-divider>
                  <v-container>
                    <v-layout row wrap>
                      <v-flex xs12 sm6 md3>
                        <v-checkbox
                          v-model="votoselected"
                          label="Voto"
                          color="success"
                          hide-details
                          @change="change1"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex xs12 sm6 md3>
                        <v-checkbox
                          v-model="vozselected"
                          label="Voz"
                          color="success"
                          hide-details
                          @change="change2"
                        ></v-checkbox>
                      </v-flex>
                      <v-flex xs12 sm6 md3>
                        <v-checkbox
                          v-model="adminselected"
                          label="Admin"
                          color="success"
                          hide-details
                          @change="change3"
                        ></v-checkbox>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-app>
        </div>
      </v-list>
    </v-card>
  </v-dialog>
</template>

  <script>
import { mapActions } from "vuex";
//import axios from "axios";
export default {
  name: "adduser",
  components: {},
  data() {
    return {
      vozselected: false,
      votoselected: false,
      adminselected: false,
      seelayout3: false,
      form: {
        TpPadron: null,
        Voto: "N",
        Voz: "N",
        Admin: "N",
        Activo: "1",
        Agremiado: "S",
        Junta: "S",
      },
      selectedsubst: [],
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      add: "padron/addpadron",
    }),
    change1() {
      //console.log(this.votoselected);
      if (this.votoselected == true) {
        this.form.Voto = "S";
      } else {
        this.form.Voto = "N";
      }
    },
    change2() {
      //console.log(this.vozselected);
      if (this.vozselected == true) {
        this.form.Voz = "S";
      } else {
        this.form.Voz = "N";
      }
    },
    change3() {
      //console.log(this.votoselected);
      if (this.adminselected == true) {
        this.form.Admin = "S";
      } else {
        this.form.Admin = "N";
      }
    },
    submit() {
      //console.log(this.form);
      if (!this.form.TpPadron) {
        alert("Nombre requerido.");
      } else {
        this.add(this.form)
          .then(() => {
            alert("Padron Agregado con exito");
            window.location.reload();
            //console.log(this.adduserget.payload.IdUsuario);
          })
          .catch(() => {
            //console.log("fail");
            alert("El Padron Fallo al Agregar, Intentelo de Nuevo Mas Tarde.");
          });
      }
    },
  },
  computed: {},
  mounted() {},
};
</script>